import React from "react";

export default function Footer({ space }) {
  console.log("space", space);
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${
            space ? "pt-150" : "pt-60"
          }`}
          style={{ backgroundColor: "#000" }}
        >
          {/* <img
            src="assets/img/pipo.png"
            alt="waves"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
              zIndex: 1,
            }}
          /> */}
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logo-header.svg"
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  <p>
                    Tu guía de entrenamiento en la palma de tu mano. Con planes
                    diseñados científicamente, Trainify te acompaña paso a paso
                    hacia tus metas de forma fácil y divertida.
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a
                          href="#/"
                          target="_blank"
                          title="Facebook"
                          rel="noreferrer"
                        >
                          <span className="ti-facebook"></span>
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a
                          href="#/"
                          target="_blank"
                          title="Instagram"
                          rel="noreferrer"
                        >
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Recursos</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#/">Help</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Events</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Live Support</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Open Sources</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Documentation</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">About Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Careers</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Customers</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Community</a>
                        </li>
                        <li className="mb-2">
                          <a href="#team">Our Team</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Ubicación</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          515 E Grant Street
                          <br />
                          Phoenix, AZ 85004
                        </li>

                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:service@trainify.app">
                            {" "}
                            service@trainify.app
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="#/"> www.trainify.app</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      Trainify® Todos los derechos son reservados
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a className="small-text" href="/deleteData">
                          Eliminación de datos
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="/terms">
                          Terminos
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a className="small-text" href="/privacyPolicy">
                          Aviso de Privacidad
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
