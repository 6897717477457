import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

const HeroOne = () => {
  const [bannerIndex, setBannerIndex] = useState(0);
  const bannerTexts = useMemo(
    () => [
      "Entrena de manera inteligente y personalizada",
      "Te puede entrenar en el gimnasio o en casa con una banda elástica o bandas de suspensión.",
      "Tu no tienes que hacer nada, la aplicación te dirá qué hacer cada día que la abras",
      "Establece una meta y mide tu progreso. Compite con tus amigos y sube de nivel",
    ],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerIndex(
        (prevBannerIndex) => (prevBannerIndex + 1) % bannerTexts.length
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [bannerTexts, bannerTexts.length]);

  return (
    <>
      <section
        id="home"
        className="hero-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.png')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between py-5">
            <div className="col-md-7 col-lg-6">
              <div className="hero-content-left text-white">
                <h1 className="text-white">
                  <span>Tu coach de ejercicio</span> en la palma de tu mano
                </h1>
                <p className="lead">{bannerTexts[bannerIndex]}</p>
                <form action="#" method="post" className="subscribe-form">
                  <div className="d-flex align-items-center">
                    <a href="#download" className="download-btn">
                      Descargala Ahora
                    </a>
                  </div>
                </form>

                <div className="video-promo-content py-4 d-flex align-items-center">
                  <Link
                    to="#"
                    className="popup-youtube video-play-icon-without-bip video-play-icon mr-3"
                  >
                    <span
                      className="ti-control-play"

                      // onClick={() => setOpen(true)}
                    ></span>
                  </Link>{" "}
                  Ver Video Resumen
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="hero-animation-img">
                <img
                  src="assets/img/phone-hero.png"
                  alt="wave shape"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          {/* <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId="9No-FiEInLA"
            onClose={() => setOpen(false)}
          /> */}
        </div>
        <div className="bottom-img-absolute">
          <img
            src="assets/img/hero-bg-shape-1.svg"
            alt="wave shape"
            className="img-fluid"
          />
        </div>
      </section>
    </>
  );
};

export default HeroOne;
