import React, { useEffect, useMemo, useState } from "react";

export default function DeleteDataScreen() {
  const [deactivateAccountIndex, setDeactivateAccountIndex] = useState(0);
  const [reactivateAccountIndex, setReactivateAccountIndex] = useState(0);

  const deactivateArr = useMemo(
    () => [
      "assets/img/109.png",
      "assets/img/110.png",
      "assets/img/111.png",
      "assets/img/112.png",
    ],
    []
  );

  const reactivateArr = useMemo(
    () => ["assets/img/113.png", "assets/img/114.png"],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDeactivateAccountIndex(
        (prevDeactivateAccountIndex) =>
          (prevDeactivateAccountIndex + 1) % deactivateArr.length
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [deactivateArr, deactivateArr.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setReactivateAccountIndex(
        (prevReactivateAccountIndex) =>
          (prevReactivateAccountIndex + 1) % reactivateArr.length
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [reactivateArr, reactivateArr.length]);

  return (
    <>
      <section
        id="features"
        style={{ backgroundColor: "#222222", color: "#fff" }}
        className="feature-section ptb-100"
      >
        <h1 style={{ color: "#fff", marginLeft: "20%", marginBottom: "50px" }}>
          Eliminación de cuenta y datos asociados
        </h1>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/data-security.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2 style={{ color: "#fff" }}>
                  Tu Privacidad es Nuestra Prioridad
                </h2>
                <p>
                  En nuestra compañía, valoramos y protegemos la privacidad de
                  tus datos personales. Conforme a nuestra Política de
                  Privacidad, mantenemos tus datos únicamente mientras sea
                  necesario para brindarte nuestros servicios o cumplir con
                  obligaciones legales. Si decides eliminar tu cuenta, todos los
                  datos sensibles serán eliminados de manera permanente, salvo
                  en casos específicos donde la ley requiera que retengamos
                  cierta información para resolver disputas o cumplir con
                  normativas legales. Nos comprometemos a tomar todas las
                  medidas necesarias para garantizar la seguridad de tus datos,
                  incluyendo el uso de cifrado y controles de acceso rigurosos.
                  Para más detalles, puedes consultar nuestra Política de
                  Privacidad completa.
                </p>
                <p>
                  En esta sección te diremos como desactivar tu cuenta
                  temporalmente y como eliminar tu cuenta con todos tus datos:
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Desactiva Tu Cuenta Temporalmente
                </h2>
                <ul style={styles.list}>
                  <li style={styles.listItem}>
                    Paso 1: Iniciar sesión en la app y navegar a la
                    configuración de la cuenta.
                  </li>
                  <li style={styles.listItem}>
                    Paso 2: Seleccionar la opción ajustes de cuenta.
                  </li>
                  <li style={styles.listItem}>
                    Paso 3: Seleccionar la opción "Eliminación de la cuenta".
                  </li>
                  <li style={styles.listItem}>
                    Paso 4: Seleccionar la opción "Desactivar cuenta".
                  </li>
                  <li style={styles.listItem}>
                    Paso 5: Confirmar la desactivación.
                  </li>
                </ul>
                <p>
                  Desactivar tu cuenta no borrará tu progreso ni los datos tuyos
                  almacenados en nuestro servidor. Tu cuenta se mantendrá
                  desactivada hasta que decidas reactivarla
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={deactivateArr[deactivateAccountIndex]}
                  alt="download"
                  className="img-fluid"
                  style={{
                    marginBottom: "30px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="features"
        style={{ backgroundColor: "#222222", color: "#fff" }}
        className="feature-section ptb-100"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={reactivateArr[reactivateAccountIndex]}
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2 style={{ color: "#fff" }}>Reactivar Tu Cuenta</h2>
                <ul style={styles.list}>
                  <li style={styles.listItem}>
                    Paso 1: Intentar iniciar sesión con las credenciales
                    existentes.
                  </li>
                  <li style={styles.listItem}>
                    Paso 2: Recibir un email de confirmación.
                  </li>
                  <li style={styles.listItem}>
                    Paso 3: Hacer clic en el enlace para reactivar la cuenta.
                  </li>
                  <li style={styles.listItem}>
                    Paso 4: Inicia sesión con normalidad".
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Eliminar Tu Cuenta Permanentemente
                </h2>
                <ul style={styles.list}>
                  <li style={styles.listItem}>
                    Paso 1: Iniciar sesión en la app y navegar a la
                    configuración de la cuenta.
                  </li>
                  <li style={styles.listItem}>
                    Paso 2: Seleccionar la opción ajustes de cuenta.
                  </li>
                  <li style={styles.listItem}>
                    Paso 3: Seleccionar la opción "Eliminación de la cuenta".
                  </li>
                  <li style={styles.listItem}>
                    Paso 4: Seleccionar la opción "Eliminar cuenta
                    permanentemente".
                  </li>
                  <li style={styles.listItem}>
                    Paso 5: Confirmar la desactivación.
                  </li>
                </ul>
                <p>
                  Todos los datos, incluidos los datos sensibles, serán
                  eliminados permanentemente. Para volver a usar nuestros
                  servicios es necesario la creación de una nueva cuenta
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={deactivateArr[deactivateAccountIndex]}
                  alt="download"
                  className="img-fluid"
                  style={{ marginBottom: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const styles = {
  container: {
    padding: "40px",
    backgroundColor: "#222222",
    overflow: "hidden",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "20px",
    color: "#fff",
  },
  date: {
    fontSize: "14px",
    marginBottom: "10px",
    color: "#fff",
  },
  paragraph: {
    fontSize: "16px",
    marginBottom: "10px",
    color: "#fff",
  },
  subHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
    color: "#fff",
  },
  subSubHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "5px",
    color: "#fff",
  },
  h4: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "13px",
    marginBottom: "3px",
    color: "#fff",
  },
  list: {
    marginLeft: "10px",
    color: "#fff",
    paddingLeft: "20px",
  },
  listItem: {
    fontSize: "16px",
    marginBottom: "5px",
    color: "#fff",
    listStyleType: "disc", // Asegúrate de usar 'disc' para ver los puntos correctamente
    listStylePosition: "inside", // Para alinear los puntos con el texto
  },
  strong: {
    fontWeight: "bold",
    color: "#fff",
  },
  link: {
    color: "#0cfcfe",
    textDecoration: "underline",
  },
};
