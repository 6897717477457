import Contact from "../../components/contact/Contact";
import Cta from "../../components/cta/Cta";
import Features from "../../components/features/Features";
import FeaturesTwo from "../../components/features/FeaturesTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Price from "../../components/prices/Price";
import Promo from "../../components/promo/Promo";
import HeroOne from "./HeroOne";
import React from "react";

export default function HomeOne() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroOne />
      <Promo />
      <Features />
      <FeaturesTwo />
      <Cta />
      <Price />
      {/* <Accordion /> */}
      {/* <TestimonialTwo /> */}
      {/* <VideoPromo /> */}
      {/* <Screenshot /> */}
      {/* <LatestNewsOne /> */}
      <Contact bgColor />
      {/* <BrandCarousel /> */}
      {/* <Subsribe /> */}
      <Footer space />
    </Layout>
  );
}
