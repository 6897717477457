import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>
                  Descubre por qué Trainify es tu mejor aliado para entrenar
                </h2>
                <p className="lead">
                  Estas son las razones por las que Trainify transformará tu
                  manera de entrenar
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <img
                    src="assets/img/brain.png"
                    alt="wave shape"
                    className="img-fluid"
                  />
                </div>
                <h5>Entrena de manera inteligente y personalizada</h5>
                <p>
                  Descubre cómo nuestra app diseña un plan de entrenamiento
                  único para ti, basado en una evaluación física sencilla y en
                  tus características personales. ¡Entrena a tu ritmo y con el
                  apoyo que necesitas para lograr tus metas! Empieza hoy mismo a
                  entrenar de manera más inteligente
                </p>
                <div
                  className="d-flex align-items-center"
                  style={{ justifyContent: "center" }}
                >
                  <a href="#download" className="download-btn">
                    Descargala Ahora
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <img
                    src="assets/img/ubi-train.png"
                    alt="wave shape"
                    className="img-fluid"
                  />
                </div>
                <h5>Entrena en cualquier momento y lugar</h5>
                <p>
                  Ya sea en el gimnasio o desde la comodidad de tu hogar,
                  nuestra app te guía en cada ejercicio. Solo necesitas una
                  banda elástica o bandas de suspensión para entrenar en casa y
                  empezar a ver resultados
                </p>

                <div
                  className="d-flex align-items-center"
                  style={{ justifyContent: "center" }}
                >
                  <a href="#download" className="download-btn">
                    Descargala Ahora
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <img
                    src="assets/img/chart-train.png"
                    alt="wave shape"
                    className="img-fluid"
                  />
                </div>
                <h5>
                  Alcanza tu mejor versión mientras monitoreas cada progreso
                </h5>
                <p>
                  La app registra cada sesión de entrenamiento y te muestra cómo
                  mejoras semana a semana. A medida que cumples con tus
                  objetivos, subes de nivel, reflejando tu compromiso. Monitorea
                  tu fuerza, resistencia cardiovascular y flexibilidad, y
                  compáralos con meses anteriores para ver cuánto has avanzado
                </p>
                <div
                  className="d-flex align-items-center"
                  style={{ justifyContent: "center" }}
                >
                  <a href="#download" className="download-btn">
                    Descargala Ahora
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
