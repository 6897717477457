import React from "react";

const FeaturesTwo = () => {
  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/phone-health.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "center" }}
              >
                <a href="#download" className="download-btn">
                  Descargala Ahora
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2>Adaptado a tu estilo de vida y a tus metas</h2>
                <p>
                  Ya sea que busques mejorar tu{" "}
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    salud
                  </span>
                  , lograr un cuerpo{" "}
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    estético
                  </span>
                  , o llevar tu{" "}
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    rendimiento deportivo
                  </span>
                  al siguiente nivel, Trainify se adapta a ti. Nuestra app
                  ofrece entrenamientos personalizados para todas las personas,
                  desde los{" "}
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    18 hasta los 65 años
                  </span>
                  . ¿Eres un atleta profesional o simplemente haces ejercicio
                  por bienestar? Configura tu plan para que se enfoque en tus
                  necesidades y metas, ya sea para mejorar tu resistencia,
                  reducir tu grasa corporal o mantener una vida activa y
                  saludable.
                </p>
                <p>
                  Trainify es para todos: mujeres, hombres, adultos mayores, y
                  deportistas, con entrenamientos diseñados científicamente para
                  alcanzar tus objetivos. ¡Tú decides cómo quieres entrenar!
                </p>

                <ul className="list-inline mt-5">
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/health.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/strength.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/sport.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesTwo;
