const translateToSpanish = {
  esp: {
    "Select a language:": "Selecciona un idioma:",
    "Select your level:": "Selecciona tu nivel:",
    "Select your body type:":
      "Selecciona el tipo de cuerpo que sea más acorde al tuyo:",
    "Select the number of days you plan to train in the week:":
      "Selecciona el número de días que vas a entrenar a la semana:",
    "Select your environment:": "Selecciona tu entorno:",
    "Select the order of your sessions, our system brings you the best planification, but evn you can custom your days:":
      "Selecciona el orden de tus sessiones de entrenamiento:",
    Spanish: "Español",
    French: "Frances",
    English: "Inglés",
    Language: "Idioma",
    Next: "siguiente",
    Condition: "Condición",
    Beginner: "Principiante",
    Intermediate: "Intermedio",
    Advanced: "Avanzado",
    prev: "atrás",
    Ectomorph: "Ectomorfo",
    Mesomorph: "Mesomorfo",
    Endomorph: "Endomorfo",
    Sport: "Deporte",
    Fitness: "Imagen",
    Health: "Salud",
    "Body type": "Tipo de cuerpo",
    Environment: "Entorno",
    Goal: "Objetivo",
    "Select your goal:": "selecciona tu objetivo. Solo puede ser 1",
    "Number of training days": "Número de días de entrenamiento",
    Home: "Casa",
    Enter: "Entrar",
    Username: "Usuario",
    Password: "Contraseña",
    "Don't have an account yet? click": "¿No tienes una cuenta aún? haz click",
    HERE: "AQUÍ",
    Register: "Registrarse",
    Email: "Correo",
    Start: "Empieza",
    "Create Account": "Crear Cuenta",
    "Access with google": "Accede con google",
    get: "ir",
    BACK: "ATRÁS",
    Gender: "Género",
    "An unknown error occurred": "Se ha detectado un error desconocido",
    "No active account found with the given credentials":
      "No se econtró ninguna cuenta activa con esas credenciales",
    "Login successful!": "Inicio de sessión exitoso",
    "Privacy Notice - TRAINIFY": "AVISO DE PRIVACIDAD - TRAINIFY",
    "Welcome to Trainify! We value your privacy and strive to protect your personal information. This Privacy Notice outlines how we collect, use, and protect your personal information when using our services.":
      "Este aviso de privacidad detalla cómo Trainify maneja tus datos personales al usar nuestros servicios. Nos apegamos a la Ley Federal de Protección de Datos Personales en Posesión de Particulares (Artículos 144 y 145)",
    "We collect the following personal information: Email, Phone, number, NamePassword, Gender, Gym membership, Date of birth, Weight, Heart rate, Height, Morphology, Age, Physical condition, Training objectives, Training days, Fiber type, Environment, Daily meals":
      "Incluyen: correo, teléfono, nombre, contraseña, género, gimnasio, fecha de nacimiento, peso, frecuencia cardiaca, altura, morfología, edad, condición física, objetivos, días de entrenamiento, tipo de fibras, entorno y comidas diarias.",
    "We gather data through: Registration forms, activity tracking within the application":
      "capturamos la informacion a través de formularios y seguimiento de actividad dentro de la aplicación.",
    "We use your data to: Create personalized training and nutrition plans based on your preferences and objectives, Improve and tailor your Trainify experience":
      "Usamos tu información para elaborar planes de entrenamiento y nutrición personalizados basados en tus características, preferencias y objetivos.",
    "Only you access your data. We have the highest standards of security and encryption of personal information.":
      "Solo tú accedes a tus datos. Contamos con los más altos estándares de seguridad y cifrado de la información personal.",
    "We protect your data using:": "Protegemos tus datos mediante:",
    "info hashing": "Hasheo de información.",
    "session protection": "Protección de sesión",
    "User and session ID verification":
      "Verificación de IDs de usuario y sesión",
    "AWS malware protection services": "AWS malware protection services",
    "We store your data until you decide to delete your account.":
      "Guardamos tus datos hasta que decidas eliminar tu cuenta.",
    "This notice may be updated in accordance with laws and regulations. For any questions, please visit our support section.":
      " Podemos modificar este aviso según leyes y regulaciones. Si tienes dudas, visita nuestra sección de soporte.",
    accept: "acepto",
    "Privacy Policy": "Aviso de Privacidad",
    "Terms and Conditions": "Términos y Condiciones",
    and: "y",
    Back: "Atrás",
    "2. Information We Collect": "2. Datos que Recopilamos",
    "3. How We Collect Information": "3. Método de Recolección",
    "4. Purpose of Collection": "4. Objetivo de la Recolección",
    "5. Data Access": "5. Acceso a los datos",
    "6. Data Protection": "6. Protección de tus Datos",
    "7. Data Storage": "7. Almacenamiento de los Datos",
    "8. Updates to This Notice": "8. Actualizaciones al aviso",
    "1. Acceptance of Terms": "1. Aceptación de los Términos",
    "By using Trainify's services, you agree to be bound by these Terms and Conditions. If you do not agree with these terms, you should not use our services.":
      "Al usar los servicios de Trainify, aceptas estar vinculado por estos Términos y Condiciones. Si no estás de acuerdo con estos términos, no deberías utilizar nuestros servicios.",
    "2. Use of Our Services": "2. Uso de Nuestros Servicios",
    "2.1 Pay for Use: As a Trainify customer, you will be charged only for the services you utilize.":
      "2.1 Pago por Uso: Como cliente de Trainify, se te cobrará únicamente por los servicios que utilices.",
    "2.2 No Refunds: Upon making a purchase with Trainify, you acknowledge that all sales are final and there will be no refunds. Ensure you are confident in your purchase before finalizing.":
      "2.2 No Reembolsos: Al realizar una compra en Trainify, aceptas que todas las ventas son finales y no habrá devoluciones. Asegúrate de estar seguro de tu compra antes de finalizarla.",
    "3. Proper Use": "3. Uso Adecuado",
    "You commit to using our services in a proper and respectful manner. Any misuse, including but not limited to, platform abuse, fraudulent use, tampering, or attempts to alter our systems, can result in the suspension or permanent banning of your access to our services.":
      "Te comprometes a utilizar nuestros servicios de manera adecuada y respetuosa. Cualquier mal uso, incluyendo, pero no limitado a, el abuso de la plataforma, el uso fraudulento, la manipulación o el intento de alteración de nuestros sistemas, puede resultar en la suspensión o prohibición permanente de tu acceso a nuestros servicios.",
    "4. Termination of Service": "4. Terminación del Servicio",
    "Trainify reserves the right to suspend or terminate your access to our services if we believe your behavior breaches these terms or is harmful to other users, Trainify, or third parties, or for any other reason, at our sole discretion.":
      "Trainify se reserva el derecho de suspender o terminar tu acceso a nuestros servicios si consideramos que tu comportamiento viola estos términos o es perjudicial para otros usuarios, para Trainify o para terceros, o por cualquier otro motivo, a nuestro exclusivo criterio.",
    "5. Limitation of Liability": "5. Limitación de Responsabilidad",
    "Trainify will not be liable for indirect, incidental, special, exemplary, or consequential damages, including lost profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of, or inability to access or use our services; (ii) any conduct or content of any third party on the services.":
      "Trainify no será responsable por daños indirectos, accidentales, especiales, ejemplares o consecuentes, incluyendo pérdida de ganancias, datos, uso, buena voluntad u otras pérdidas intangibles, resultantes del (i) acceso o uso, o incapacidad para acceder o usar nuestros servicios; (ii) cualquier conducta o contenido de terceros en los servicios.",
    "6. Modification of Terms": "6. Modificación de los Términos",
    "We reserve the right to modify these Terms and Conditions at any time. It is your responsibility to regularly review these Terms and Conditions to be aware of any changes. You will Also find this section in user account settings":
      "Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Es tu responsabilidad revisar regularmente estos Términos y Condiciones para estar al tanto de cualquier cambio. Esta sección también la puedes encontrar en los ajustes de la cuenta",
    "7. Contact": "7. Contacto",
    "For any questions related to these Terms and Conditions, please contact our support team.":
      "Para cualquier pregunta relacionada con estos Términos y Condiciones, por favor contacta a nuestro equipo de soporte.",
    "Terms of Service - TRAINIFY":
      "Términos y Condiciones del Servicio - TRAINIFY",
    "Create a username": "Crea un nombre de usuario",
    "Create a password": " Crea una contraseña",
    "Confirm the password": "Confirma la contraseña",
    "fill this field": "llena este campo",
    "password must be at least 8 characters":
      "la contraseña debe ser de al menos 8 caractéres",
    "invalid email, please enter a valid email":
      "Email inválido, pof favor pon uno válido",
    "password need to be alfanumeric": "la contraseña debe ser alfanumérica",
    "passwords do not match": "las contraseñas no coinciden",
    "password must be alfanumeric": "la contraseña debe de ser alfanumérica",
    "Confirm that you aren't a robot": "Confirma que no eres un robot",
    "We send you a verification email. Please check your inbox":
      "Te hemos enviado un email de verificacion. Revisa spam también",
    "Account created successfuly": "Cuenta creada exitosamente",
    "Email confirmation - TRAINIFY": "Confirmación de correo - TRAINIFY",
    "Your email has been verified!": "¡Tú email ha sido verificado!",
    "return to login": "volver al inicio de sesión",
    "The time for validating your email is over, clic down to get a new validation email":
      "Se acabó el tiempo para validar tu email, haz clic abajo para que te llegue un nuevo email de validación",
    "Thank you for verifying your email": "Gracias por verificar tu correo",
    "User update successfully!": "Usuario actualizado exitosamente!",
    "Login through Google Failed": "Fallo al ingresar por medio de Googel",
    "Reset password": "Reestablecer contraseña",
    "Email was sent to your inbox": "Un email fue enviado a tu bandeja",
    "Reset Password": "Reestablecer Contraseña",
    "Registered email": "Email que registraste",
    "Did you forgot your password? Click":
      "Has olvidado la contraseña ? Haz clic",
    here: "aquí",
    "Network Error": "Error en la red, intenta más terde",
    "Password Reset - TRAINIFY": "Restablecer contraseña - TRAINIFY",
    Restore: "Restaurar",
    "Get another link": "Obtén otro enlace",
    "Reset send to your email": "email de restablecimiento enviado a tu correo",
    "We have sent to you another password restore link, check your inbox":
      "Te hemos enviado otro enlace al correo para restablecer tu contraseña, revisa spam si es necesario",
    "Link has expired, pleas click the button to get another link":
      "El enlace ha expirado, por favor has clic en obtener otro enlace",
    "We have sent to you the link to reset your password":
      "Te hemos enviado a tu correo el link para restablecer tu contraseña",
    "You need to validate your email first": "Primero verifica tu email",
    Male: "Masculino",
    Female: "Femenino",
    "Select your gender:": "Selecciona tu género",
    "Select your username:": "Selecciona un nombre de usuario:",
    "Do you need a different order? press":
      "¿Necesitas un orden diferente? Toca",
    Hello: "Hola",
    "Order of your training": "Orden de tu entrenameinto",
    Level: "Nivel",
    "You need to completed your training evaluation before you see this chart":
      "Necesitas completar tu evaluación de entrenamiento antes de ver esta tabla",
    "Week progress": "Avance semanal",
    "Month progress": "Avance mensual",
    "Plan progress": "Avance del programa",
    "Cardio Progress": "Avance Cardiovascular",
    "Meal Plan Progress": "Avance Nutricional",
    "Strength Progress": "Avance Fuerza",
    "Strength training": "Entrenamiento de fuerza",
    "Cardio training": "Entrenamiento de Cardio",
    "Flex training": "Entrenamiento de Flexibilidad",
    "1. Have you ever been diagnosed by a doctor with a heart problem, recommending that you only do sports under medical supervision?":
      "1.¿Alguna vez te ha diagnosticado tu médico un problema en el corazón, recomendándole que solo haga deporte bajo supervisión médica?",
    "2. Do you feel chest pain when exercising or during intense physical activity?":
      "2.¿Sientes dolor en el pecho al hacer ejercicio o alguna actividad física intensa?",
    "3. Have you had chest pain in the last month?":
      "3.¿Has tenido dolor en el pecho durante el último mes?",
    "4. Have you lost consciousness or balance as a result of frequent dizziness?":
      "4.¿Has perdido el conocimiento o el equilibrio como resultado de mareos de forma frecuente?",
    "5. Have you ever been prescribed medication for high blood pressure or another circulatory problem?":
      "5.¿Alguna vez te ha recetado medicamentos para la presión arterial u otro problema cardiocirculatorio?",
    "6. Do you have joint problems or any pain that worsens with exercise?":
      "6.¿Tienes problemas en las articulaciones o algún dolor que se agrava haciendo ejercicio?",
    "7. Do you have any other medical recommendation advising against high-intensity sports?":
      "7.¿Cuentas con alguna otra recomendación médica que te recomiende no hacer deporte con mucha intensidad?",
    "8. Have you experienced unexplained weight gain or loss?":
      "8.¿Has subido o bajado de peso sin razón aparente?",
    "9. Have you been told that you have high cholesterol levels?":
      "9.¿Te han mencionado que tu nivel de colesterol es elevado?",
    "10. Do you suffer from diabetes or insulin resistance?":
      "10.¿Padeces diabetes o resistencia a la insulina?",
    "11. Do you experience shortness of breath when walking?":
      "11.¿Experimentas falta de aliento al caminar?",
    "12. Do you have pains or cramps in your legs?":
      "12.¿Presentas dolores o calambres en las piernas?",
    "13. Do you suffer from any chronic or serious illness?":
      "13.¿Padeces alguna enfermedad crónica o grave?",
    "14. On a scale of 1 to 10, select your stress level:":
      "14. Del 1 al 10, selecciona tu nivel de estrés:",
    "15. Do you have trouble sleeping?": "15.¿Tienes problemas para dormir?",
    "16. Have you ever smoked cigarettes, cigars, or a pipe?":
      "16.¿Has fumado alguna vez cigarrillos, puros o pipa?",
    "17. Are you currently a smoker?": "17.¿Fumas actualmente?",
    "18. How often do you consume alcohol in a month?":
      "18.¿Cuál es la frecuencia con la que consumes alcohol al mes?",
    "19. Do you do exercises like push-ups, squats, etc.?":
      "19.¿Realizas ejercicios como lagartijas, sentadillas, etc.?",
    "20. Do you exercise regularly by walking, jogging, or running?":
      "20.¿Realizas ejercicio como caminata, trote o correr de forma regular?",
    "21. Do you do exercises that include stretching such as yoga or pilates?":
      "21.¿Realizas ejercicios que incluyan estiramientos como yoga o pilates?",
    "22. Briefly describe your meals, if you skip any, answer no.":
      "22. Describe brevemente cómo son tus comidas, en caso de no hacer una, responde no.",
    "23. Do you engage in any activity while eating?":
      "23.¿Haces alguna actividad mientras comes?",
    "24. How many times a week do you consume the following foods: Sweets, cakes or pastries, boxed juices, milk or dairy drinks, fried foods, sodas, fruits?":
      "24.¿Cuántas veces por semana consumes los siguientes alimentos: Dulces, tartas o pasteles, jugos en tetrapack, leche o bebidas lácteas, frituras y alimentos fritos, refrescos, frutas?",
    "25. Do you add salt to your food?": "25.¿Le pones sal a tu comida?",
    "26. Have you tried to change your body through diet or exercise?":
      "26.¿Has intentado un cambio en tu cuerpo mediante dieta o ejercicio?",
    "27. How many months or years have you continuously been under a physical fitness program?":
      "27.¿Cuántos meses o años has durado bajo un programa de acondicionamiento físico de forma continua?",
    "28. Can you exercise after a hard day at work?":
      "28.¿Puedes hacer ejercicio después de un día duro en el trabajo?",
    "29. Do you have any food allergies?":
      "29.¿Tienes alergia por algún alimento?",
    "Regarding the organization of exercises, monosets and bisets are convenient for you, this way you will gain muscle mass efficiently.":
      "En cuanto a la organización de los ejercicios, te convienen las monoseries y las biseries, de esta forma ganarás masa muscular de forma eficiente.",
    "Regarding the organization of exercises, circuits are convenient for you, this way you will lose fat and gain muscle efficiently.":
      "En cuanto a la organización de los ejercicios, te convienen los circuitos, de esta forma perderás grasa y ganarás músculo de forma eficiente.",
    "Regarding the organization of exercises, trisets are convenient for you, this way you will lose fat and gain muscle efficiently.":
      "En cuanto a la organización de los ejercicios, te convienen las triseries, de esta forma perderás grasa y ganarás músculo de forma eficiente.",
    "Given your current condition, it is recommended that you work 6 to 8 exercises per session, depending on the training block.":
      "Por tu condición actual, se recomienda que trabajes 6 a 8 ejercicios por sesión, dependiendo del bloque de entrenamiento.",
    "Given your current condition, it is recommended that you work 5 to 7 exercises per session, depending on the training block.":
      "Por tu condición actual, se recomienda que trabajes de 5 a 7 ejercicios por sesión, dependiendo del bloque de entrenamiento.",
    "Given your current condition, it is recommended that you work 4 to 6 exercises per session, depending on the training block.":
      "Por tu condición actual, se recomienda que trabajes de 4 a 6 ejercicios por sesión, dependiendo del bloque de entrenamiento.",
    "Due to your type of muscle fibers, we recommend short rest time between sets and exercises.":
      "Por tu tipo de fibras musculares, te recomendamos poco tiempo de descanso entre series y ejercicios.",
    "Due to your type of muscle fibers, we recommend a moderate rest time between sets and exercises.":
      "Por tu tipo de fibras musculares, te recomendamos un tiempo moderado de descanso entre series y ejercicios.",
    "Due to your type of muscle fibers, we recommend a long rest time between sets and exercises.":
      "Por tu tipo de fibras musculares, te recomendamos largo tiempo de descanso entre series y ejercicios.",
    "Considering the number of days you have selected to train during the week, we recommend giving priority to multi-joint exercises, where large muscles predominate.":
      "Por el número de días que has seleccionado para entrenar en la semana, te recomendamos dar prioridad a los ejercicios multiarticulares, donde predominan los músculos grandes.",
    "Considering the number of days you have selected to train during the week, we recommend a variety in your exercises between multi-joint and single-joint exercises.":
      "Por el número de días que has seleccionado para entrenar en la semana, te recomendamos variedad en tus ejercicios entre multiarticulares y uniarticulares.",
    "For your goal of": "Para tu objetivo de ",
    "and in the period you are in, the block we are preparing for you is muscle gain.":
      "y en el periodo en el que te encuentras, el bloque que estamos preparando para ti es de ganancia muscular.",
    "and in the period you are in, the block we are preparing for you is maximum strength.":
      "y en el periodo en el que te encuentras, el bloque que estamos preparando para ti es de fuerza máxima.",
    "and in the period you are in, the block we are preparing for you is power.":
      "y en el periodo en el que te encuentras, el bloque que estamos preparando para ti es de potencia.",
    fit: "imágen fisica",
    hea: "salud",
    spo: "mejora deportiva",
    "Beginning of the Evaluation": "Comienzo de la Evaluación",
    "Health Form": "Cuestionario de Salud",
    "Before you begin your assessment, make sure you are at your training center ready to move your body.":
      "Antes de comenzar tu evaluación, asegúrate de estar en tu lugar de entrenamiento, listo para mover tu cuerpo.",
    "Muscular Fibers": "Fibras Musculares",
    Fast: "Rápidas",
    Mixed: "Mixtas",
    Slow: "Lentas",
    "Exercise Selection": "Seleccion de Ejercicios",
    "Muscular group": "Grupo muscular",
    Hardness: "Dificultad",
    option: "opción",
    Select: "seleccionar",
    "Exercises assessment": "Evaluación de Selección",
    "Go up and down a bench of approx. 43 cm high to the rhythm of the metronome. Then, check your heart rate when you finish.":
      "Subir y bajar un banco de aprox. 43 cm de altura al ritmo del metrónomo. después, revisar la frecuencia cardiaca al finalizar.",
    "The Rockport test involves walking 1.6 kilometers (1 mile) on a treadmill, maintaining the fastest pace you can without running. At the end, your heart rate will be measured. This test is ideal for evaluating the physical condition of older adults or overweight people, as it is a safe and controlled way to measure aerobic capacity.":
      "La prueba de Rockport consiste en caminar 1.6 kilómetros (1 milla) en una caminadora, manteniendo el ritmo más rápido que puedas sin correr. Al finalizar, se medirá tu frecuencia cardíaca. Esta prueba es ideal para evaluar la condición física de adultos mayores o personas con sobrepeso, ya que es una forma segura y controlada de medir la capacidad aeróbica.",
    start: "empezar",
    "Introduce your heart rate to complete the test:":
      "Introduce to frecuencia cardiaca para completar la prueba:",
    finish: "terminar",
    restart: "restablecer",
    "The recommended time per session, according to the number of days you selected, should be":
      "El tiempo recomendado por sesión, de acuerdo al número de días que seleccionaste debe ser",
    "Your physical condition is excellent. Keep your current routine and keep challenging yourself.":
      "Tu condición física es excelente. Mantén tu rutina actual y sigue desafiándote a ti mismo.",
    "You are in good physical condition. Consider increasing the intensity or duration of your workouts to improve even more.":
      "Tienes una buena condición física. Considera aumentar la intensidad o duración de tus entrenamientos para mejorar aún más.",
    "Your physical condition is average. Integrating more cardiovascular activity can help you improve.":
      "Tu condición física es promedio. Integrar más actividad cardiovascular puede ayudarte a mejorar.",
    "You are below average in physical condition. Increasing physical activity regularly is important for your health.":
      "Estás por debajo del promedio en condición física. Incrementar la actividad física regularmente es importante para tu salud.",
    "It is important that you seek professional advice to improve your physical condition safely.":
      "Es importante que busques asesoramiento profesional para mejorar tu condición física de manera segura.",
    "Your level of Vo2mx is:": "Tu nivel de Vo2max es de:",
    "According to your age and gender:": "De acuerdo a tu edad y genero:",
    "Cardio Test obtained successfully":
      "Evaluación de cardio obtenida exitosamente",
    "Restricted Exercises": "Ejercicios restringidos",
    "Unfortunately, the month and a half time to complete your plan has ended. You will drop a level and lose the progress you were making. The Evaluation you had will lose validity. We will ask you for a new one.":
      "Lamentablemente, el tiempo de mes y medio para completar tu plan ha terminado. Bajarás de nivel y perderás el progreso que estabas haciendo. La Evaluacion que tenias perderá vigencia. Te pediremos una nueva.",
    "Add back to restricted exercises":
      "Volver a poner en ejercicios restringidos",
    "For this routine we will ask you your weight":
      "Para esta rutina te preguntaremos tu peso",
    "before your plan expires": "antes de que tu plan expire",
    "you have until": "tienes hasta",
    "Remove from restricted exercises": "Quitar de ejercicios restringidos",
    "Today is your cardio off day": "Hoy te toca descansar de hacer cardio",
    "Today you have to do": "Hoy tienes que hacer",
    "of cardio": "de cardio",
    "Sit down on the floor and with hand fingers try to touch or rebase you toes as much as you can for 4 seconds":
      "Siéntate en el suelo y con los dedos de las manos, intenta tocar o rebasar la punta de los pies durante 4 segundos.",
    "This are the muscles you must work today:":
      "Estos son los músculos que te tocan hoy:",
    "Cardio test successfully created":
      "Prueba cardiovascular creada con éxito",
    "Cardio test was not created": "La prueba cardiovascular no fue creada",
    "Successfully created strength assessment":
      "Evaluación de fuerza creada exitosamente",
    "Strength assessment was not created":
      "la evaluación de fuerza no fue creada",
    "Successfully created flex assessment":
      "evaluación de flexibilidad creada exitosamente",
    "Flex assessment was not created":
      "la evaluación de Flexibilidad no fue creada",
    "In this moment our services are not available. Please try later":
      "En este momento nuestro servicios están saturado. Por favor intente mas tarde",
    "Wrong credentials": "Credenciales inválidas",
    "User Account Successfully Obtained": "Cuenta obtenida exitosamente",
    "User account not obtained": "Cuenta no obtenida",
    "Flex test Successfully Obtained":
      "Evaluación de flexibilidad obtenida exitosamente",
    "You have until Sunday": "Tienes hasta el domingo",
    "You need to completed your strength evaluation before you see this chart":
      "Debes completar tu evaluación de fuerza antes de ver este 'Tablero.",
    "You need to completed your cardio evaluation before you see this chart":
      "Debes completar tu evaluación cardiovascular antes de ver este cuadro.",
    January: "enero",
    February: "febrero",
    March: "marzo",
    April: "abril",
    May: "mayo",
    June: "junio",
    July: "julio",
    August: "agosto",
    September: "septiembre",
    October: "octubre",
    November: "noviembre",
    December: "diciembre",
    "We will retain sensitive data for as long as your account is active. If you decide to delete your account, all sensitive data associated with your profile will be permanently deleted, except in cases where it is necessary to retain certain information for legal reasons or to resolve disputes. In such cases, we will retain only the minimum data necessary to comply with our legal obligations.":
      "Retendremos los datos sensibles, mientras tu cuenta esté activa. Si decides eliminar tu cuenta, todos los datos sensibles asociados con tu perfil serán eliminados de manera permanente, salvo en los casos en que sea necesario retener cierta información por razones legales o para resolver disputas. En tales casos, retendremos únicamente los datos mínimos necesarios para cumplir con nuestras obligaciones legales.",
    "to finish your weekly routine and advance to the next level":
      "para completar la rutina semanal y avanzar al próximo nivel",
    "Congratulations, you have completed the weekly routine, wait until the following Monday to see your new level reflected next level.":
      "Felicidades, has completado la rutina semanal, espera al lunes siguiente para ver tu nuevo nivel reflejado siguiente nivel.",
    "Days that I must train strength:": "Días que debo entrenar fuerza:",
    monday: "lunes",
    tuesday: "martes",
    wednesday: "miércoles",
    thursday: "jueves",
    friday: "viernes",
    "...loading": "...cargando",
    "...sending email": "...enviando correo",
    saturday: "sábado",
    "Enter the new password": "Ingresa la nueva contraseña",
    "Email sent": "Correo enviado",
    "confirm password": "confirma la contraseña",
    sunday: "domingo",
    "Email not found": "Email no encontrado",
    "the passwords didn't match": "las contraseñas no coinciden",
    "Congratulations, you complete your daily routine":
      "¡Felicidades, has completado la rutina de hoy!",
    "Days I choose to flex:": "Días que debo hacer flexibilidad:",
    "Congratulations, you complete your daily flex routine":
      "¡Felicidades, has completado la session de flexibilidad de hoy!",
    "Flex Progress": "Avance Flexibilidad",
    Strength: "Fuerza",
    Cardio: "Cardio",
    Flex: "Flexibiidad",
    Store: "Tienda",
    User: "Perfil",
    Nutrition: "Nutrición",
    "create a post:": "crear una publicación:",
    soon: "próximamente",
    "Mono-set": "Monoserie",
    "Bi-set": "Biserie",
    "Tri-set": "Triserie",
    Circuit: "Circuito",
    Quadriceps: "Cuádriceps",
    Send: "Enviar",
    Adductors: "Aductores",
    Psoas: "Psoas Iliaco",
    "femoral Biceps": "Biceps Femoral",
    Hamstrings: "Isquiotibiales",
    "Glute Maximus": "Gluteo Mayor",
    "Glute Minimus": " Gluteo Menor",
    "Glute Medius": "Gluteo Medio",
    Calves: "Pantorrilla",
    "Tibialis Anterior": "Tibial Anterior",
    "Pectoralis Major": "Pectoral Major",
    "Pectoralis Minor": "Pectoral Menor",
    "Latissimus Dorsi": "Dorsal Ancho",
    "Scapular Bundle": "Paquete Escapular",
    Trapezius: "Trapecio",
    "Anterior Deltoid": "Deltoide Anterior",
    "Lateral Deltoid": "Deltoide Lateral",
    "Posterior Deltoid": "Deltoide Posterior",
    "Biceps Brachii": "Biceps Braquial",
    "Triceps Brachii": "Triceps Braquial",
    "Rectus Abdominis": "Recto Abdominal",
    Obliques: "Oblicuos",
    "Erector Spinae": "Espalda Baja",
    sets: "series",
    rest: "descansar",
    "Mon | Thu": "Lun | Jue",
    "Tue | Fri": "Mar | Vie",
    "Wed | Sat": "Mie | Sab",
    "Thu | Sun": "Jue | Dom",
    "Mon | Wed | Fri": "Lun | Mie | Vie",
    "Tue | Thu | Sat": "Mar | Jue | Sab",
    "Wed | Fri | Sun ": "Mie | Vie | Dom",
    "Mon | Tue | Thu | Fri": "Lun | Mar | Jue | Vie",
    "Tue | Wed | Fri | Sat": "Mar | Mie | Vie | Sab",
    "Wed | Thu | Sat | Sun": "Mie | Jue | Sab | Dom",
    "Mon | Tue | Wed | Thu | Fri": "Lun | Mar | Mie | Jue | Vie",
    "Tue | Wed | Thu | Fri | Sat": "Mar | Mie | Jue | Vie | Sab",
    "Wed | Thu | Fri | Sat | Sun": "Mie | Jue | Vie | Sab | Dom",
    "Mon | Thu | Wed | Thu | Fri | Sat": "Lun | Mar | Mie | Jue | Vie | Sab",
    "Tue | Wed | Thu | Fri | Sat | Sun": "Mar | Mie | Jue | Vie | Sab | Dom",
    "Today you must res from strength trainning":
      "Hoy debes descansar del entrenamiento de fuerza",
    "Days selection": "Selección de días",
    "Give a touch to order you training cardio week:":
      "Toca los dias que vas a hacer cardio:",
    Physiology: "Fisiología",
    "weight (kg)": "peso (kg)",
    "resting heart rate (bpm)": "Frecuencia cardiaca en reposo (lpm)",
    YYYY: "AAAA",
    MM: "MM",
    DD: "DD",
    Eliptic: "Eliptica",
    Treadmil: "Caminadora",
    Stepmil: "Escalera sin fin",
    "Estationary Bike": "Bici estacionaria",
    "Cardio Row": "Remo",
    "Congratulations, you have complete the cardio for today!":
      "Felicidades, has completado el cardio de hoy!",
    "According the test results, you most do at least":
      "De acuerdo a los resultodos de la prueba, necesitas hacer por lo menos",
    "flex sessions to prevent back pain":
      "sesiones de flexibilidad para prevenir dolores de espalda",
    Results: "Resultados",
    "To what position you get?": "¿A qué posición llegaste?",
    "I couldn't reach my toes": "No pude alcanzar la punta de mis pies",
    "I could touch my toes": "Pude tocar la punta de mis pies",
    "I rebase my toes": "Pude rebasar la punta de mis pies",
    "Give a touch to order you training flex week:":
      "Marca las casillas de los días que vas a hacer tus sesiones",
    "Today you have to rest": "Hoy debes descansar",
    Training: "Entrenamiento",
    "Account Preferences": "Ajustes de perfil",
    Billing: "Facturación",
    "You must complete you cardio evaluation to update your preferences":
      "Debes completar la evaluación de cardio para poder actualizar estas preferencias",
    "You must complete you flex evaluation to update your preferences":
      "Debes completar la evaluación de flexibilidad para poder actualizar estas preferencias",
    "unfortunately you did not complete the weekly routine on time. so you will go down a level.":
      "Lamentablemente no completaste la rutina semanal a tiempo. así que bajarás un nivel.",
    Continue: "continuar",
    "Days I choose to cardio:": "Días que debo de hacer cardio:",
    "Today you have flex session": "Hoy debes hacer flexibilidad",
    flex: "estira",
    "last one": "ultima",
    "Swan Press": "Saludo al sol",
    "Prayer position": "Postura Mahometana",
    "Knee to chest": "Rodilla al Pecho",
    "Information not available": "Información no disponible",
    Update: "Actualizar",
    "We collect the following personal information: Email, Phone, number, Name, Password, Gender, Gym membership, Date of birth, Weight, Heart rate, Height, Morphology, Age, Physical condition, Training objectives, Training days, Fiber type, Environment, Daily meals, health information.":
      "Recopilamos la siguiente información personal: Correo electrónico, Teléfono, número, Nombre, Contraseña, Sexo, Membresía del gimnasio, Fecha de nacimiento, Peso, Frecuencia cardíaca, Altura, Morfología, Edad, Condición física, Objetivos de entrenamiento, Días de entrenamiento, Tipo de fibra, Medio ambiente , Comidas diarias e Información de salud.",
    slow: "lentas",
    mixed: "mixtas",
    fast: "rápidas",
    "How many time you want to trian cardio ? The minimum is 3:":
      "¿Cuántos días desea entrenar cardio? El mínimo son 3:",
    "User Account successfully updated": "Cuenta actualizada exitosamente",
    "select..": "selecciona..",
    "This test consists of raising and lowering a bench approximately 43 cm high following the rhythm of a metronome. Men should maintain a rhythm of 96 beats per minute (bpm), while women should maintain a rhythm of 88 bpm. The objective is to continue this cycle of up and down for a certain period of time. This test is not recommended for older adults, people with heart conditions, overweight, arrhythmias, or respiratory problems, since it involves considerable cardiovascular effort.":
      "Esta prueba consiste en subir y bajar un banco de aproximadamente 43 cm de altura siguiendo el ritmo de un metrónomo. Los hombres deben mantener un ritmo de 96 pulsaciones por minuto (bpm), mientras que las mujeres deben seguir un ritmo de 88 bpm. El objetivo es continuar este ciclo de subida y bajada durante un periodo determinado de tiempo.Esta prueba no es recomendada para adultos mayores, personas con afecciones cardíacas, sobrepeso, arritmias, o problemas respiratorios, ya que implica un esfuerzo cardiovascular considerable.",
    "Set a metronome at 88 bpm and that will be the rhythm with which you must raise and lower the 43 cm bench or approximately":
      "Acciona un metronomo a 88 bpm y ese será el ritmo con el que debas subir y bajar el banco de 43 cm o aproximado",
    "Keep your rhythm between": "Mantén tu ritmo entre",
    "Continue with google": "Continuar con Google",
    "It is time to create your account for getting your training plan and save your progress.":
      "Es momento de obtener tu plan de entrenamiento y guardar tu progreso",
    "Lets create your account!": "¡Vamos a crear tu cuenta!",
    "By registering you accept our terms and conditions":
      "Al registrarte aceptas nuestros terminos y condiciones",
    "Do you already have an account? press": "¿Ya tienes una cuenta? presiona",
    "Continue with Google": "Continuar con Google",
    "Register with E-MAIL": "Regitrase con E-MAIL",
    "Please introduce your account credentials.":
      "Por favor introduce los datos de tu cuenta.",
    "Forgot your password?": "¿Olvidaste tu contraseña?",
    "By registering you accept our": "Al registrarte estás aceptando nuestros",
    "terms and conditions": "términos y condiciones",
    eng: "ing",
    spa: "esp",
    "Last updated: August 30, 2024":
      "Última actualización: 30 de agosto de 2024",
    "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.":
      "Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando usa el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.",
    "We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.":
      "Usamos sus datos personales para proporcionar y mejorar el servicio. Al usar el servicio, usted acepta la recopilación y el uso de la información de acuerdo con esta política de privacidad.",
    "Interpretation and Definitions": "Interpretación y Definiciones",
    Interpretation: "Interpretación",
    "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.":
      "Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.",
    Definitions: "Definiciones",
    "For the purposes of this Privacy Policy:":
      "A los efectos de esta política de privacidad:",
    Account: "Cuenta",
    "means a unique account created for You to access our Service or parts of our Service.":
      "significa una cuenta única creada para que usted acceda a nuestro servicio o partes de nuestro servicio.",
    Affiliate: "Afiliado",
    "means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.":
      "significa una entidad que controla, es controlada por o está bajo el control común con una parte, donde 'control' significa la propiedad del 50% o más de las acciones, participación o valores que dan derecho a voto para la elección de directores u otra autoridad administrativa.",
    Application: "Aplicación",
    "refers to Trainify, the software program provided by the Company.":
      "se refiere a Trainify, el programa de software proporcionado por la empresa.",
    Business: "Negocio",
    "for the purpose of CCPA/CPRA, refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.":
      "para los propósitos de CCPA/CPRA, se refiere a la Compañía como la entidad legal que recopila la información personal de los consumidores y determina los propósitos y medios del procesamiento de la información personal de los consumidores, o en nombre de la cual se recopila dicha información y que sola, o conjuntamente con otros, determina los propósitos y medios del procesamiento de la información personal de los consumidores, que hace negocios en el estado de California.",
    CCPA: "CCPA",
    CPRA: "CPRA",
    "refers to the California Consumer Privacy Act (the 'CCPA') as amended by the California Privacy Rights Act of 2020 (the 'CPRA').":
      "se refiere a la Ley de Privacidad del Consumidor de California (la 'CCPA') según enmendada por la Ley de Derechos de Privacidad de California de 2020 (la 'CPRA').",
    Company: "Compañía",
    "referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to Trainify.":
      "(referida como 'la Compañía', 'Nosotros' o 'Nuestro' en este Acuerdo) se refiere a Trainify.",
    "For the purpose of the GDPR, the Company is the Data Controller.":
      "A los efectos del GDPR, la Compañía es el Controlador de Datos.",
    Consumer: "Consumidor",
    "for the purpose of the CCPA/CPRA, means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.":
      "para los fines del CCPA/CPRA, significa una persona natural que es residente de California. Un residente, según se define en la ley, incluye (1) a toda persona que se encuentre en los EE. UU. para un propósito que no sea temporal o transitorio, y (2) a toda persona que esté domiciliada en los EE. UU. que se encuentre fuera de los EE. UU. para un propósito temporal o transitorio.",
    Country: "País",
    "refers to: Mexico": "se refiere a: México",
    "Data Controller": "Controlador de Datos",
    "for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.":
      "a los efectos del GDPR (Reglamento General de Protección de Datos), se refiere a la Compañía como la persona jurídica que sola o conjuntamente con otros determina los propósitos y medios del procesamiento de Datos Personales.",
    Device: "Dispositivo",
    "means any device that can access the Service such as a computer, a cellphone or a digital tablet.":
      "significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono móvil o una tableta digital.",
    "Do Not Track": "No Rastrear",
    "DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.":
      "DNT) es un concepto que ha sido promovido por las autoridades reguladoras de EE. UU., en particular la Comisión Federal de Comercio de EE. UU. (FTC), para que la industria de Internet desarrolle e implemente un mecanismo que permita a los usuarios de Internet controlar el seguimiento de sus actividades en línea a través de sitios web.",
    "Facebook Fan Page": "Página de Fans de Facebook",
    "is a public profile named Trainify.mx specifically created by the Company on the Facebook social network, accessible from":
      "es un perfil público llamado Trainify.mx creado específicamente por la Compañía en la red social Facebook, accesible desde",
    "Visit our Facebook Page": "Visite nuestra Página de Facebook",
    GDPR: "GDPR",
    "If you decide to delete your account, but prefer that your data remain stored for possible future use, we may retain the information associated with your account. This includes your personal data, settings, training history and health questionnaires. If you want to reactivate your account in the future, you can easily recover your information. Please note that although your account will be deactivated and you will not be able to access the services of the application, your data will be maintained in our system to facilitate the recovery of your account when you decide to do so. You can always request complete deletion of your data at any time. Note: We will retain your data only to facilitate the reactivation of your account and to comply with our legal obligations. They will not be used for any other purpose.":
      "Si decides eliminar tu cuenta, pero prefieres que tus datos permanezcan almacenados para un posible uso futuro, podemos conservar la información asociada a tu cuenta. Esto incluye tus datos personales, configuraciones, historial de entrenamientos y cuestionarios de salud. Si en el futuro deseas reactivar tu cuenta, podrás recuperar tu información de manera sencilla. Ten en cuenta que, aunque tu cuenta estará desactivada y no podrás acceder a los servicios de la aplicación, tus datos se mantendrán en nuestro sistema para facilitar la recuperación de tu cuenta cuando lo decidas. Siempre puedes solicitar la eliminación completa de tus datos en cualquier momento. Nota: Conservaremos tus datos únicamente para facilitar la reactivación de tu cuenta y cumplir con nuestras obligaciones legales. No se utilizarán para ningún otro propósito.",
    "delete account": "eliminar cuenta",
    "If you decide to delete your account, all data associated with your profile will be permanently deleted, in compliance with our privacy policy and Google Play regulations. This includes your personal data, training history, health questionnaires and any other information stored on our platform. Important: Once the deletion is complete, it will not be possible to recover your account or associated information. We recommend that you download any relevant data before proceeding with the deletion. However, in specific cases where we are required by law to retain certain information, we will retain only the minimum data necessary to comply with our legal obligations. By confirming the deletion of your account, you understand and agree that the information will be securely and permanently deleted.":
      "Si decides eliminar tu cuenta, todos los datos asociados a tu perfil serán eliminados de manera permanente, en cumplimiento con nuestra política de privacidad y las normativas de Google Play. Esto incluye tus datos personales, historial de entrenamientos, cuestionarios de salud y cualquier otra información almacenada en nuestra plataforma. Importante: Una vez que se complete la eliminación, no será posible recuperar tu cuenta ni la información asociada. Te recomendamos descargar cualquier dato relevante antes de proceder con la eliminación. Sin embargo, en casos específicos donde la ley nos exija conservar cierta información, retendremos únicamente los datos mínimos necesarios para cumplir con nuestras obligaciones legales. Al confirmar la eliminación de tu cuenta, entiendes y aceptas que la información será borrada de manera segura y definitiva.",
    "refers to EU General Data Protection Regulation.":
      "se refiere al Reglamento General de Protección de Datos de la UE.",
    "Delete account and data": "Borrar cuanta y datos",
    cancel: "cancelar",
    "...loading your monthly plan": "...cargando tu plan mensual",
    "You have no evaluations": "Aún no tienes evaluaciones",
    "...deleting account and data": "...eliminado cuenta y datos",
    "Delete just account": "Borrar solo cuenta",
    "is any information that relates to an identified or identifiable individual.":
      "es cualquier información que se relacione con una persona identificada o identificable.",
    "For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.":
      "A los efectos del GDPR, Datos Personales significa cualquier información relacionada con usted, como un nombre, un número de identificación, datos de ubicación, identificador en línea o con uno o más factores específicos de la identidad física, fisiológica, genética, mental, económica, cultural o social.",
    "For the purposes of the CCPA/CPRA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.":
      "A los fines de CCPA/CPRA, Datos Personales significa cualquier información que identifique, se relacione con, describa o pueda asociarse con, o pueda vincularse razonablemente, directa o indirectamente, con usted.",
    Service: "Servicio",
    "Choose a weight that you can lift between 6 and 18 times until you can no longer do any more repetitions, always maintaining good technique. Try to use the greatest weight possible for each exercise you have selected, without losing correct posture.":
      "Elige un peso que puedas levantar entre 6 y 18 veces hasta que ya no puedas hacer más repeticiones, manteniendo siempre una buena técnica. Trata de usar el mayor peso posible para cada ejercicio que hayas seleccionado, sin perder la postura correcta.",
    "refers to the Application.": "se refiere a la Aplicación.",
    "Service Provider": "Proveedor de Servicios",
    "means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.":
      "significa cualquier persona física o jurídica que procese los datos en nombre de la Compañía. Se refiere a empresas o individuos terceros contratados por la Compañía para facilitar el Servicio, proporcionar el Servicio en nombre de la Compañía, realizar servicios relacionados con el Servicio o ayudar a la Compañía a analizar cómo se utiliza el Servicio. A los efectos del GDPR, los Proveedores de Servicios se consideran Procesadores de Datos.",
    "Third-party Social Media Service":
      "Servicio de Redes Sociales de Terceros",
    "refers to any website or any social network website through which a User can log in or create an account to use the Service.":
      "se refiere a cualquier sitio web o cualquier sitio web de redes sociales a través del cual un usuario puede iniciar sesión o crear una cuenta para utilizar el servicio.",
    "Usage Data": "Datos de Uso",
    "refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).":
      "se refiere a los datos recopilados automáticamente, ya sea generados por el uso del servicio o por la propia infraestructura del servicio (por ejemplo, la duración de una visita a la página).",
    You: "Usted",
    "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.":
      "significa la persona que accede o utiliza el servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el servicio, según corresponda.",
    "Under GDPR, You can be referred to as the Data Subject or as the User as you are the individual using the Service.":
      "Según el GDPR, usted puede ser referido como el sujeto de datos o como el usuario, ya que es la persona que usa el servicio.",
    "Collecting and Using Your Personal Data":
      "Recopilación y Uso de sus Datos Personales",
    "Types of Data Collected": "Tipos de Datos Recopilados",
    "Personal Data": "Datos Personales",
    "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:":
      "Al utilizar nuestro servicio, podemos solicitarle que nos proporcione cierta información de identificación personal que pueda usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, pero no se limita a:",
    "Email address": "Dirección de correo electrónico",
    "First name": "Nombre",
    "Usage Data is collected automatically when using the Service.":
      "Los datos de uso se recopilan automáticamente al utilizar el servicio.",
    "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.":
      "Los datos de uso pueden incluir información como la dirección de Protocolo de Internet (por ejemplo, dirección IP) de su dispositivo, tipo de navegador, versión del navegador, las páginas de nuestro servicio que visita, la hora y fecha de su visita, el tiempo dedicado a esas páginas, identificadores de dispositivos únicos y otros datos de diagnóstico.",
    "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.":
      "Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, incluidos, entre otros, el tipo de dispositivo móvil que usa, el ID único de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivos únicos y otros datos de diagnóstico.",
    "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.":
      "También podemos recopilar información que su navegador envía cada vez que visita nuestro servicio o cuando accede al servicio a través de un dispositivo móvil.",
    "Information from Third-Party Social Media Services":
      "Información de Servicios de Redes Sociales de Terceros",
    "The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:":
      "La Compañía le permite crear una cuenta e iniciar sesión para utilizar el Servicio a través de los siguientes Servicios de Redes Sociales de Terceros:",
    "If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.":
      "Si decide registrarse a través de un Servicio de Redes Sociales de Terceros o de otro modo, podemos recopilar datos personales que ya estén asociados con la cuenta de su Servicio de Redes Sociales de Terceros, como su nombre, su dirección de correo electrónico, sus actividades o su lista de contactos asociada con esa cuenta.",
    "You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.":
      "También puede tener la opción de compartir información adicional con la Compañía a través de la cuenta de su Servicio de Redes Sociales de Terceros. Si decide proporcionar dicha información y datos personales, durante el registro o de otro modo, está otorgando a la Compañía permiso para usar, compartir y almacenar dicha información de manera coherente con esta Política de Privacidad.",
    "Information Collected while Using the Application":
      "Información Recopilada al Usar la Aplicación",
    "While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:":
      "Al usar nuestra aplicación, para proporcionar funciones de nuestra aplicación, podemos recopilar, con su permiso previo:",
    "Pictures and other information from your Device's camera and photo library":
      "Imágenes y otra información de la cámara y la biblioteca de fotos de su dispositivo",
    "We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.":
      "Usamos esta información para proporcionar funciones de nuestro servicio, para mejorar y personalizar nuestro servicio. La información puede subirse a los servidores de la Compañía y/o a los servidores de un Proveedor de Servicios o puede almacenarse simplemente en su dispositivo.",
    "You can enable or disable access to this information at any time, through Your Device settings.":
      "Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de su dispositivo.",
    "Use of Your Personal Data": "Uso de sus Datos Personales",
    "The Company may use Personal Data for the following purposes:":
      "La Compañía puede usar Datos Personales para los siguientes propósitos:",
    "To provide and maintain our Service, including to monitor the usage of our Service.":
      "Para proporcionar y mantener nuestro servicio, incluyendo monitorear el uso de nuestro servicio.",
    "To manage Your Account:": "Para administrar su cuenta:",
    "to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.":
      "para gestionar su registro como usuario del servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.",
    "For the performance of a contract:": "Para la ejecución de un contrato:",
    "the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.":
      "el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que ha comprado o de cualquier otro contrato con Nosotros a través del Servicio.",
    "To contact You:": "Para contactarlo:",
    "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.":
      "Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones push de la aplicación móvil relacionadas con actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.",
    "To provide You": "Para proporcionarle",
    "with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.":
      "noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya ha comprado o sobre los que ha solicitado información, a menos que haya optado por no recibir dicha información.",
    "To manage Your requests:": "Para gestionar sus solicitudes:",
    "To attend and manage Your requests to Us.":
      "Para atender y gestionar sus solicitudes hacia nosotros.",
    "To deliver targeted advertising to You":
      "Para entregarle publicidad dirigida",
    ": We may use Your information to develop and display content and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and to measure its effectiveness.":
      ": Podemos usar su información para desarrollar y mostrar contenido y publicidad (y trabajar con proveedores externos que lo hagan) adaptados a sus intereses y/o ubicación y para medir su efectividad.",
    "We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.":
      "Podemos usar su información para evaluar o llevar a cabo una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como parte de una quiebra, liquidación o procedimiento similar, en el cual los Datos Personales que poseemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.",
    "For other purposes": "Para otros propósitos",
    ": We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.":
      ": Podemos usar su información para otros fines, como análisis de datos, identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro servicio, productos, servicios, marketing y su experiencia.",
    "We may share Your personal information in the following situations:":
      "Podemos compartir su información personal en las siguientes situaciones:",
    "With Service Providers:": "Con proveedores de servicios:",
    "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to advertise on third party websites to You after You visited our Service, for payment processing, to contact You.":
      "Podemos compartir su información personal con proveedores de servicios para monitorear y analizar el uso de nuestro servicio, para anunciarse en sitios web de terceros después de que haya visitado nuestro servicio, para el procesamiento de pagos, para contactarlo.",
    "For business transfers:": "Para transferencias comerciales:",
    "We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.":
      "Podemos compartir o transferir su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la compañía, financiación o adquisición de la totalidad o parte de nuestro negocio a otra compañía.",
    "With Affiliates:": "Con afiliados:",
    "We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.":
      "Podemos compartir su información con nuestras afiliadas, en cuyo caso requeriremos que esas afiliadas respeten esta Política de Privacidad. Las afiliadas incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlamos o que están bajo control común con nosotros.",
    "With business partners:": "Con socios comerciales:",
    "We may share Your information with Our business partners to offer You certain products, services or promotions.":
      "Podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.",
    "With other users:": "Con otros usuarios:",
    "when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.":
      "cuando comparte información personal o interactúa en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede ser distribuida públicamente fuera de la plataforma. Si interactúa con otros usuarios o se registra a través de un Servicio de Redes Sociales de Terceros, sus contactos en el Servicio de Redes Sociales de Terceros pueden ver su nombre, perfil, fotos y descripción de su actividad. Del mismo modo, otros usuarios podrán ver descripciones de su actividad, comunicarse con usted y ver su perfil.",
    "With Your consent": "Con su consentimiento",
    ": We may disclose Your personal information for any other purpose with Your consent.":
      ": Podemos divulgar su información personal para cualquier otro propósito con su consentimiento.",
    "Retention of Your Personal Data": "Retención de sus Datos Personales",
    "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.":
      "La Compañía conservará sus Datos Personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad. Conservaremos y utilizaremos sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.",
    "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.":
      "La Compañía también conservará los Datos de Uso para fines de análisis interno. Los Datos de Uso generalmente se conservan durante un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o estamos legalmente obligados a retener estos datos durante períodos de tiempo más largos.",
    "Transfer of Your Personal Data": "Transferencia de sus Datos Personales",
    "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.":
      "Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede ser transferida a — y mantenida en — computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.",
    "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.":
      "Su consentimiento a esta Política de Privacidad, seguido de su envío de dicha información, representa su acuerdo con esa transferencia.",
    "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.":
      "La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de manera segura y de acuerdo con esta Política de Privacidad y que no se realizará ninguna transferencia de sus Datos Personales a una organización o país a menos que existan controles adecuados, incluida la seguridad de sus datos y otra información personal.",
    "Delete Your Personal Data": "Eliminar sus Datos Personales",
    "You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.":
      "Usted tiene derecho a eliminar o solicitar que le ayudemos a eliminar los Datos Personales que hemos recopilado sobre usted.",
    "Our Service may give You the ability to delete certain information about You from within the Service.":
      "Nuestro Servicio puede brindarle la capacidad de eliminar cierta información sobre usted desde dentro del Servicio.",
    "You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.":
      "Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión en su cuenta, si tiene una, y visitando la sección de configuración de la cuenta que le permite administrar su información personal. También puede contactarnos para solicitar acceso, corregir o eliminar cualquier información personal que nos haya proporcionado.",
    "Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.":
      "Tenga en cuenta, sin embargo, que es posible que necesitemos conservar cierta información cuando tengamos una obligación legal o una base legal para hacerlo.",
    "Disclosure of Your Personal Data": "Divulgación de sus Datos Personales",
    "Business Transactions": "Transacciones Comerciales",
    "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.":
      "Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus Datos Personales pueden ser transferidos. Le proporcionaremos un aviso antes de que sus Datos Personales sean transferidos y queden sujetos a una Política de Privacidad diferente.",
    "Law enforcement": "Aplicación de la ley",
    "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).":
      "En ciertas circunstancias, la Compañía puede verse obligada a divulgar sus Datos Personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).",
    "Other legal requirements": "Otros requisitos legales",
    "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:":
      "La Compañía puede divulgar sus Datos Personales con la creencia de buena fe de que dicha acción es necesaria para:",
    "Comply with a legal obligation": "Cumplir con una obligación legal",
    "Protect and defend the rights or property of the Company":
      "Proteger y defender los derechos o la propiedad de la Compañía",
    "Prevent or investigate possible wrongdoing in connection with the Service":
      "Prevenir o investigar posibles irregularidades en relación con el Servicio",
    "Protect the personal safety of Users of the Service or the public":
      "Proteger la seguridad personal de los usuarios del Servicio o del público",
    "Protect against legal liability":
      "Protegerse contra la responsabilidad legal",
    "This account was deleted. Do you want to reactivate it again? We will send you an email.":
      "Esta cuenta fue eliminada. ¿Quieres reactivarla nuevamente? Te enviaremos un correo electrónico.",
    "...creating your routine": "...creando tu rutina",
    "Security of Your Personal Data": "Seguridad de sus Datos Personales",
    "Reactivate my account": "Reactivar mi cuenta",
    "No se encontró una cuenta desactivada con este correo electrónico.":
      "No deactivated account was found with this email.",
    "Congratulations! Your account has been reactivated successfully":
      "¡Felicidades! tu cuenta he sido reactivada exitosamente",
    "1.1. Scope and purpose of the contract":
      "1.1. Alcance y objeto del contrato",
    "1.2. Supplier": "1.2. Proveedor",
    "2. Services": "2. Servicios",
    "2.2. Changes in the scope of services":
      "2.2. Cambios en el alcance de los servicios",
    "You can use Trainify in its free basic version or subscribe to the paid Pro version, which unlocks all the features. For more details about the services included in both versions, check the app description in the App Store or Play Store.":
      "Puedes utilizar Trainify en su versión básica gratuita o suscribirte a la versión Pro de pago, que desbloquea todas las funcionalidades. Para obtener más detalles sobre los servicios incluidos en ambas versiones, consulta la descripción de la aplicación en la App Store o Play Store.",
    "2.1. Free and paid services": "2.1. Servicios gratuitos y de pago",
    "To use the application you must be over 18 years old.":
      "Para utilizar la aplicación debes ser mayor de 18 años.",
    "6.1. Account Deletion": "6.1. Eliminación de la cuenta",
    "6. Termination of the Contract": "6. Terminación del contrato",
    "5.1. Fees": "5.1. Tarifas",
    "4.1. Health requirements": "4.1 Requisitos de salud",
    "4.2. User Conduct": "4.2. Conducta del Usuario",
    "4.3. Usage Rights": "4.3. Derechos de uso",
    "3.1. App download": "3.1. Descarga de la aplicación",
    "3.2. Basic version": "3.2. Versión básica",
    "3.3. Pro version": "3.3. Versión pro",
    "4. User Obligations": "4. Obligaciones del usuario",
    "5. Fees and Payment": "5. Tarifas and Pagos",
    "6.2. Termination for Cause": "6.2. Terminación por causa justificada",
    "We reserve the right to make reasonable changes to the app's services, taking your interests into consideration.":
      "Nos reservamos el derecho de hacer cambios razonables en los servicios de la aplicación, tomando en cuenta tus intereses.",
    "To begin using the app, you must download it from the App Store or Play Store.":
      "Para comenzar a usar la aplicación, primero debes descargarla desde la App Store o Play Store.",
    "To use the basic version, you need to register by providing your email address and choosing a password. By registering, you agree to these Terms and Conditions and our Privacy Policy.":
      "Para usar la versión básica, deberás registrarte proporcionando tu dirección de correo electrónico y una contraseña. Al hacerlo, aceptas estos Términos y Condiciones y nuestra Política de Privacidad.",
    "You can subscribe to the Pro version with either a monthly or annual subscription, which is processed via the App Store or Play Store.":
      "Puedes suscribirte a la versión Pro con una suscripción mensual o anual, la cual se procesa a través de la App Store o Play Store.",
    "Using the app is at your own risk. It is important to ensure you are in good health before starting any training program. If you have any pre-existing medical conditions, please consult a doctor before using the app.":
      "El uso de la aplicación es bajo tu propio riesgo. Es importante que asegures estar en buen estado de salud antes de comenzar cualquier programa de entrenamiento. Si tienes alguna condición médica preexistente, consulta a tu médico antes de usar la aplicación.",
    "Users are required to maintain respectful behavior at all times. Aggressive, harassing, sexual, pornographic, violent, or any inappropriate conduct will not be tolerated. Trainify reserves the right to suspend or delete accounts that violate these rules.":
      "Los usuarios están obligados a mantener un comportamiento respetuoso en todo momento. No se tolerará comportamiento agresivo, acosador, sexual, pornográfico, violento o cualquier conducta inapropiada. Trainify se reserva el derecho de suspender o eliminar cuentas que violen estas reglas.",
    "You are granted a non-transferable right to use the app for personal purposes. Sharing your credentials with third parties is not allowed.":
      "Se te concede un derecho no transferible para usar la aplicación con fines personales. No se permite compartir tus credenciales con terceros.",
    "The subscription fees are detailed in the app description in the App Store or Play Store. Payment is due in advance for both monthly and yearly subscriptions.":
      "Las tarifas de suscripción se detallan en la descripción de la aplicación en la App Store o Play Store. El pago se realiza por adelantado para las suscripciones mensuales y anuales.",
    "You can request the deletion of your account at any time by contacting us via email at support@trainify.app.":
      "Puedes solicitar la eliminación de tu cuenta en cualquier momento enviando un correo electrónico a support@trainify.app.",
    "Trainify reserves the right to terminate your account in case of significant violations of these Terms and Conditions.":
      "Trainify se reserva el derecho de eliminar tu cuenta en caso de violaciones significativas de estos Términos y Condiciones.",
    "The application provider is:": "El proveedor de la aplicación es:",
    "These General Terms and Conditions (hereinafter 'GTC') set out the legal framework for the use of the Trainify app. Trainify is a fitness training app designed to generate personalized training plans, tailored to your preferences and needs. The app offers a variety of functionalities, including the recording and tracking of your training sessions.":
      "Estos Términos y Condiciones Generales (en adelante, 'TCG') establecen el marco legal para el uso de la aplicación Trainify. Trainify es una aplicación de entrenamiento físico diseñada para generar planes de entrenamiento personalizados, adaptados a tus preferencias y necesidades. La aplicación ofrece una variedad de funcionalidades, incluyendo el registro y seguimiento de tus sesiones de entrenamiento.",
    "A reactivation link has been sent to your email":
      "Se ha enviado un enlace de reactivación a tu correo electrónico",
    "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.":
      "La seguridad de sus Datos Personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.",
    "Detailed Information on the Processing of Your Personal Data":
      "Información Detallada sobre el Procesamiento de sus Datos Personales",
    "The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.":
      "Los proveedores de servicios que utilizamos pueden tener acceso a sus Datos Personales. Estos proveedores externos recopilan, almacenan, utilizan, procesan y transfieren información sobre su actividad en nuestro Servicio de acuerdo con sus Políticas de Privacidad.",
    Analytics: "Analítica",
    "We may use third-party Service providers to monitor and analyze the use of our Service.":
      "Podemos utilizar proveedores de servicios de terceros para monitorear y analizar el uso de nuestro Servicio.",
    "Google Analytics": "Google Analytics",
    "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.":
      "Google Analytics es un servicio de análisis web ofrecido por Google que rastrea y reporta el tráfico del sitio web. Google utiliza los datos recopilados para rastrear y monitorear el uso de nuestro Servicio. Estos datos se comparten con otros servicios de Google. Google puede utilizar los datos recopilados para contextualizar y personalizar los anuncios de su propia red publicitaria.",
    "You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:":
      "Puede optar por no utilizar ciertas funciones de Google Analytics a través de la configuración de su dispositivo móvil, como la configuración de publicidad de su dispositivo o siguiendo las instrucciones proporcionadas por Google en su Política de Privacidad:",
    Firebase: "Firebase",
    "Firebase is an analytics service provided by Google Inc.":
      "Firebase es un servicio de análisis proporcionado por Google Inc.",
    "We also encourage you to review the Google's policy for safeguarding your data:":
      "También le recomendamos que revise la política de Google para proteger sus datos:",
    "For more information on what type of information Firebase collects, please visit the How Google uses data when you use our partners' sites or apps webpage:":
      "Para obtener más información sobre qué tipo de información recopila Firebase, visite la página web de Cómo utiliza Google los datos cuando utiliza los sitios o aplicaciones de nuestros socios:",
    Payments: "Pagos",
    "We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).":
      "Podemos proporcionar productos y/o servicios de pago dentro del Servicio. En ese caso, podemos utilizar servicios de terceros para el procesamiento de pagos (por ejemplo, procesadores de pagos).",
    "We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.":
      "No almacenaremos ni recopilaremos los detalles de su tarjeta de pago. Esa información se proporciona directamente a nuestros procesadores de pagos de terceros cuyo uso de su información personal se rige por su Política de Privacidad. Estos procesadores de pagos cumplen con los estándares establecidos por PCI-DSS, según lo gestionado por el PCI Security Standards Council, que es un esfuerzo conjunto de marcas como Visa, Mastercard, American Express y Discover. Los requisitos de PCI-DSS ayudan a garantizar el manejo seguro de la información de pago.",
    "Google Play In-App Payments":
      "Pagos dentro de la aplicación de Google Play",
    "Their Privacy Policy can be viewed at ":
      "Su Política de Privacidad puede ser vista en ",
    "Behavioral Remarketing": "Remarketing Conductual",
    "The Company uses remarketing services to advertise to You after You accessed or visited our Service. We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and understand how You use our Service so that We can improve our Service to reflect Your interests and serve You advertisements that are likely to be of more interest to You.":
      "La Compañía utiliza servicios de remarketing para anunciarse a usted después de que haya accedido o visitado nuestro Servicio. Nosotros y nuestros proveedores externos utilizamos cookies y tecnologías que no son cookies para ayudarnos a reconocer su dispositivo y comprender cómo utiliza nuestro Servicio, para que podamos mejorar nuestro Servicio para reflejar sus intereses y ofrecerle anuncios que probablemente sean de mayor interés para usted.",
    "These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies and to enable Us to:":
      "Estos proveedores externos recopilan, almacenan, utilizan, procesan y transfieren información sobre su actividad en nuestro Servicio de acuerdo con sus Políticas de Privacidad y para permitirnos:",
    "Measure and analyze traffic and browsing activity on Our Service":
      "Medir y analizar el tráfico y la actividad de navegación en nuestro Servicio",
    "Show advertisements for our products and/or services to You on third-party websites or apps":
      "Mostrarle anuncios de nuestros productos y/o servicios en sitios web o aplicaciones de terceros",
    "Measure and analyze the performance of Our advertising campaigns":
      "Medir y analizar el rendimiento de nuestras campañas publicitarias",
    "Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser settings that block cookies. Your browser may not permit You to block such technologies. You can use the following third-party tools to decline the collection and use of information for the purpose of serving You interest-based advertising:":
      "Algunos de estos proveedores externos pueden utilizar tecnologías que no son cookies y que pueden no verse afectadas por la configuración del navegador que bloquea las cookies. Es posible que su navegador no le permita bloquear dichas tecnologías. Puede utilizar las siguientes herramientas de terceros para rechazar la recopilación y el uso de información con el propósito de ofrecerle publicidad basada en intereses:",
    "The NAI's opt-out platform:":
      "La plataforma de exclusión voluntaria de NAI:",
    "The EDAA's opt-out platform":
      "La plataforma de exclusión voluntaria de EDAA",
    "The DAA's opt-out platform:":
      "La plataforma de exclusión voluntaria de DAA:",
    "You may opt-out of all personalized advertising by enabling privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help system for more information.":
      "Puede optar por no recibir publicidad personalizada activando las funciones de privacidad en su dispositivo móvil, como Limitar el Seguimiento de Anuncios (iOS) y Excluirse de la Personalización de Anuncios (Android). Consulte el sistema de ayuda de su dispositivo móvil para obtener más información.",
    "We may share information, such as hashed email addresses (if available) or other online identifiers collected on Our Service with these third-party vendors. This allows Our third-party vendors to recognize and deliver You ads across devices and browsers. To read more about the technologies used by these third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor listed below.":
      "Podemos compartir información, como direcciones de correo electrónico cifradas (si están disponibles) u otros identificadores en línea recopilados en nuestro Servicio con estos proveedores externos. Esto permite que nuestros proveedores externos reconozcan y le entreguen anuncios a través de dispositivos y navegadores. Para obtener más información sobre las tecnologías utilizadas por estos proveedores externos y sus capacidades entre dispositivos, consulte la Política de Privacidad de cada proveedor que se enumera a continuación.",
    "The third-party vendors We use are:":
      "Los proveedores externos que utilizamos son:",
    "Google Ads (AdWords)": "Google Ads (AdWords)",
    "Google Ads (AdWords) remarketing service is provided by Google Inc.":
      "El servicio de remarketing de Google Ads (AdWords) es proporcionado por Google Inc.",
    "You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page:":
      "Puede optar por no utilizar Google Analytics para publicidad gráfica y personalizar los anuncios de la Red de Display de Google visitando la página de Configuración de anuncios de Google:",
    "Google also recommends installing the Google Analytics Opt-out Browser Add-on -":
      "Google también recomienda instalar el complemento de exclusión voluntaria de Google Analytics para el navegador -",
    "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:":
      "Para obtener más información sobre las prácticas de privacidad de Google, visite la página web de Privacidad y Términos de Google:",
    Facebook: "Facebook",
    "Facebook remarketing service is provided by Facebook Inc.":
      "El servicio de remarketing de Facebook es proporcionado por Facebook Inc.",
    "You can learn more about interest-based advertising from Facebook by visiting this page:":
      "Puede obtener más información sobre la publicidad basada en intereses de Facebook visitando esta página:",
    "To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:":
      "Para optar por no recibir anuncios basados en intereses de Facebook, siga estas instrucciones de Facebook:",
    "Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA":
      "Facebook se adhiere a los Principios Autorreguladores para la Publicidad Conductual en Línea establecidos por la Alianza de Publicidad Digital. También puede optar por no participar en Facebook y otras empresas participantes a través de la Alianza de Publicidad Digital en los EE.UU.",
    ", the Digital Advertising Alliance of Canada in Canada":
      ", la Alianza de Publicidad Digital de Canadá en Canadá",
    "or the European Interactive Digital Advertising Alliance in Europe":
      "o la Alianza Europea de Publicidad Digital Interactiva en Europa",
    ", or opt-out using your mobile device settings.":
      ", o excluirse utilizando la configuración de su dispositivo móvil.",
    "For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here:":
      "Para obtener más información sobre las prácticas de privacidad de Facebook, visite la Política de privacidad de Facebook aquí:",
    "For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:":
      "Para obtener más información sobre las prácticas de privacidad de Facebook, visite la Política de Datos de Facebook:",
    "Usage, Performance and Miscellaneous": "Uso, Rendimiento y Varios",
    "We may use third-party Service Providers to maintain and improve our Service.":
      "Podemos utilizar proveedores de servicios de terceros para mantener y mejorar nuestro Servicio.",
    FreshDesk: "FreshDesk",
    "FreshDesk is a customer support software. The service is operated by Freshworks, Inc.":
      "FreshDesk es un software de soporte al cliente. El servicio es operado por Freshworks, Inc.",
    "FreshDesk service may collect information from Your Device.":
      "El servicio de FreshDesk puede recopilar información de su dispositivo.",
    "The information gathered by FreshDesk is held in accordance with its Privacy Policy:":
      "La información recopilada por FreshDesk se mantiene de acuerdo con su Política de Privacidad:",
    "GDPR Privacy": "Privacidad de GDPR",
    "Legal Basis for Processing Personal Data under GDPR":
      "Base Legal para el Procesamiento de Datos Personales bajo el GDPR",
    "We may process Personal Data under the following conditions:":
      "Podemos procesar Datos Personales bajo las siguientes condiciones:",
    "Consent:": "Consentimiento:",
    "You have given Your consent for processing Personal Data for one or more specific purposes.":
      "Ha dado su consentimiento para el procesamiento de Datos Personales para uno o más fines específicos.",
    "Performance of a contract:": "Ejecución de un contrato:",
    "Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.":
      "La provisión de Datos Personales es necesaria para la ejecución de un acuerdo con usted y/o para cualquier obligación precontractual del mismo.",
    "Legal obligations:": "Obligaciones legales:",
    "Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.":
      "El procesamiento de Datos Personales es necesario para cumplir con una obligación legal a la que está sujeta la Compañía.",
    "Vital interests:": "Intereses vitales:",
    "Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.":
      "El procesamiento de Datos Personales es necesario para proteger sus intereses vitales o los de otra persona natural.",
    "Public interests:": "Intereses públicos:",
    "Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.":
      "El procesamiento de Datos Personales está relacionado con una tarea que se lleva a cabo en interés público o en el ejercicio de la autoridad oficial conferida a la Compañía.",
    "Legitimate interests:": "Intereses legítimos:",
    "Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.":
      "El procesamiento de Datos Personales es necesario para los fines de los intereses legítimos perseguidos por la Compañía.",
    "In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.":
      "En cualquier caso, la Compañía estará encantada de ayudar a aclarar la base legal específica que se aplica al procesamiento, y en particular si la provisión de Datos Personales es un requisito legal o contractual, o un requisito necesario para celebrar un contrato.",
    "Your Rights under the GDPR": "Sus Derechos bajo el GDPR",
    "The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.":
      "La Compañía se compromete a respetar la confidencialidad de sus Datos Personales y a garantizar que pueda ejercer sus derechos.",
    "You have the right under this Privacy Policy, and by law if You are within the EU, to:":
      "Usted tiene derecho en virtud de esta Política de Privacidad, y por ley si se encuentra dentro de la UE, a:",
    "Request access to Your Personal Data.":
      "Solicitar acceso a sus Datos Personales.",
    "The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.":
      "El derecho a acceder, actualizar o eliminar la información que tenemos sobre usted. Siempre que sea posible, puede acceder, actualizar o solicitar la eliminación de sus Datos Personales directamente dentro de la sección de configuración de su cuenta. Si no puede realizar estas acciones por sí mismo, comuníquese con nosotros para que lo ayudemos. Esto también le permite recibir una copia de los Datos Personales que tenemos sobre usted.",
    "Request correction of the Personal Data that We hold about You.":
      "Solicitar la corrección de los Datos Personales que tenemos sobre usted.",
    "You have the right to have any incomplete or inaccurate information We hold about You corrected.":
      "Tiene derecho a que se corrija cualquier información incompleta o inexacta que tengamos sobre usted.",
    "Object to processing of Your Personal Data.":
      "Oponerse al procesamiento de sus Datos Personales.",
    "This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.":
      "Este derecho existe cuando confiamos en un interés legítimo como la base legal para nuestro procesamiento y hay algo en su situación particular que lo lleva a oponerse a nuestro procesamiento de sus Datos Personales por este motivo. También tiene derecho a oponerse cuando procesamos sus Datos Personales con fines de marketing directo.",
    "Request erasure of Your Personal Data.":
      "Solicitar la eliminación de sus Datos Personales.",
    "You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.":
      "Tiene derecho a pedirnos que eliminemos o suprimamos los Datos Personales cuando no haya una razón válida para que sigamos procesándolos.",
    "Request the transfer of Your Personal Data.":
      "Solicitar la transferencia de sus Datos Personales.",
    "We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.":
      "Le proporcionaremos a usted, o a un tercero que haya elegido, sus Datos Personales en un formato estructurado, de uso común y legible por máquina. Tenga en cuenta que este derecho solo se aplica a la información automatizada que inicialmente nos proporcionó con su consentimiento o donde utilizamos la información para cumplir un contrato con usted.",
    "Withdraw Your consent.": "Retirar su consentimiento.",
    "You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.":
      "Tiene derecho a retirar su consentimiento para el uso de sus Datos Personales. Si retira su consentimiento, es posible que no podamos proporcionarle acceso a ciertas funcionalidades específicas del Servicio.",
    "Exercising of Your GDPR Data Protection Rights":
      "Ejercicio de sus Derechos de Protección de Datos bajo el GDPR",
    "You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.":
      "Puede ejercer sus derechos de acceso, rectificación, cancelación y oposición contactándonos. Tenga en cuenta que es posible que le pidamos que verifique su identidad antes de responder a dichas solicitudes. Si realiza una solicitud, haremos todo lo posible para responderle lo antes posible.",
    "You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.":
      "Tiene derecho a presentar una queja ante una Autoridad de Protección de Datos sobre nuestra recopilación y uso de sus Datos Personales. Para obtener más información, si se encuentra en el Espacio Económico Europeo (EEE), comuníquese con su autoridad local de protección de datos en el EEE.",
    "Data Controller for the Facebook Fan Page":
      "Responsable del Tratamiento de Datos de la Página de Fans de Facebook",
    "The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page":
      "La Compañía es la Responsable del Tratamiento de sus Datos Personales recopilados mientras utiliza el Servicio. Como operador de la Página de Fans de Facebook",
    ", the Company and the operator of the social network Facebook are Joint Controllers.":
      ", la Compañía y el operador de la red social Facebook son Corresponsables del Tratamiento.",
    "The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the Facebook Terms of Service:":
      "La Compañía ha celebrado acuerdos con Facebook que definen los términos para el uso de la Página de Fans de Facebook, entre otras cosas. Estos términos se basan principalmente en los Términos de Servicio de Facebook:",
    "Visit the Facebook Privacy Policy":
      "Visite la Política de Privacidad de Facebook",
    "for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.":
      "para obtener más información sobre cómo Facebook gestiona los Datos Personales o para contactar con Facebook en línea, o por correo: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, Estados Unidos.",
    "Facebook Insights": "Facebook Insights",
    "We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users.":
      "Utilizamos la función Facebook Insights en relación con la operación de la Página de Fans de Facebook y sobre la base del GDPR, con el fin de obtener datos estadísticos anonimizados sobre nuestros usuarios.",
    "For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period.":
      "Para este fin, Facebook coloca una Cookie en el dispositivo del usuario que visita nuestra Página de Fans de Facebook. Cada Cookie contiene un código identificador único y permanece activa durante un período de dos años, salvo que se elimine antes del final de este período.",
    "Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use Facebook services.":
      "Facebook recibe, registra y procesa la información almacenada en la Cookie, especialmente cuando el usuario visita los servicios de Facebook, los servicios que son proporcionados por otros miembros de la Página de Fans de Facebook y los servicios de otras empresas que utilizan los servicios de Facebook.",
    "CCPA/CPRA Privacy Notice (California Privacy Rights)":
      "Aviso de Privacidad CCPA/CPRA (Derechos de Privacidad de California)",
    "This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.":
      "Esta sección del aviso de privacidad para residentes de California complementa la información contenida en nuestra Política de Privacidad y se aplica exclusivamente a todos los visitantes, usuarios y otros que residen en el Estado de California.",
    "Categories of Personal Information Collected":
      "Categorías de Información Personal Recopilada",
    "We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months.":
      "Recopilamos información que identifica, se relaciona, describe, hace referencia, es capaz de ser asociada con, o podría razonablemente vincularse, directa o indirectamente, con un Consumidor o Dispositivo en particular. La siguiente es una lista de categorías de información personal que podemos recopilar o que puede haber sido recopilada de los residentes de California en los últimos doce (12) meses.",
    "Please note that the categories and examples provided in the list below are those defined in the CCPA/CPRA. This does not mean that all examples of that category of personal information were in fact collected by Us, but reflects our good faith belief to the best of Our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information directly to Us.":
      "Tenga en cuenta que las categorías y ejemplos proporcionados en la lista a continuación son los definidos en el CCPA/CPRA. Esto no significa que todos los ejemplos de esa categoría de información personal hayan sido realmente recopilados por nosotros, sino que refleja nuestra creencia de buena fe, en la medida de nuestro conocimiento, de que parte de esa información de la categoría aplicable puede haber sido y puede haber sido recopilada. Por ejemplo, ciertas categorías de información personal solo se recopilarían si usted proporcionara dicha información personal directamente a nosotros.",
    "Category A: Identifiers.": "Categoría A: Identificadores.",
    "Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.":
      "Ejemplos: Un nombre real, alias, dirección postal, identificador personal único, identificador en línea, dirección de Protocolo de Internet, dirección de correo electrónico, nombre de cuenta, número de licencia de conducir, número de pasaporte u otros identificadores similares.",
    "Collected: Yes.": "Recopilado: Sí.",
    "Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).":
      "Categoría B: Categorías de información personal enumeradas en el estatuto de Registros de Clientes de California (Cal. Civ. Code § 1798.80(e)).",
    "Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.":
      "Ejemplos: Un nombre, firma, número de Seguro Social, características físicas o descripción, dirección, número de teléfono, número de pasaporte, número de licencia de conducir o tarjeta de identificación estatal, número de póliza de seguro, educación, empleo, historial de empleo, número de cuenta bancaria, número de tarjeta de crédito, número de tarjeta de débito, u otra información financiera, información médica o información de seguro de salud. Parte de la información personal incluida en esta categoría puede superponerse con otras categorías.",
    "Category C: Protected classification characteristics under California or federal law.":
      "Categoría C: Características de clasificación protegidas bajo la ley de California o federal.",
    "Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).":
      "Ejemplos: Edad (40 años o más), raza, color, ascendencia, origen nacional, ciudadanía, religión o credo, estado civil, condición médica, discapacidad física o mental, sexo (incluyendo género, identidad de género, expresión de género, embarazo o parto y condiciones médicas relacionadas), orientación sexual, estado de veterano o militar, información genética (incluida la información genética familiar).",
    "Collected: No.": "Recopilado: No.",
    "Category D: Commercial information.":
      "Categoría D: Información comercial.",
    "Examples: Records and history of products or services purchased or considered.":
      "Ejemplos: Registros e historial de productos o servicios comprados o considerados.",
    "Category E: Biometric information.":
      "Categoría E: Información biométrica.",
    "Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.":
      "Ejemplos: Características genéticas, fisiológicas, conductuales y biológicas, o patrones de actividad utilizados para extraer una plantilla u otro identificador o información identificativa, como huellas dactilares, huellas faciales y de voz, escaneos de iris o retina, patrones de tecleo, marcha u otros patrones físicos, y datos de sueño, salud o ejercicio.",
    "Category F: Internet or other similar network activity.":
      "Categoría F: Actividad en Internet u otra actividad similar en la red.",
    "Examples: Interaction with our Service or advertisement.":
      "Ejemplos: Interacción con nuestro Servicio o anuncio.",
    "Category G: Geolocation data.": "Categoría G: Datos de geolocalización.",
    "Examples: Approximate physical location.":
      "Ejemplos: Ubicación física aproximada.",
    "Category H: Sensory data.": "Categoría H: Datos sensoriales.",
    "Examples: Audio, electronic, visual, thermal, olfactory, or similar information.":
      "Ejemplos: Información auditiva, electrónica, visual, térmica, olfativa o similar.",
    "Category I: Professional or employment-related information.":
      "Categoría I: Información profesional o relacionada con el empleo.",
    "Examples: Current or past job history or performance evaluations.":
      "Ejemplos: Historial laboral actual o pasado o evaluaciones de desempeño.",
    "Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).":
      "Categoría J: Información educativa no pública (según la Ley de Derechos Educativos y Privacidad de la Familia (20 U.S.C. Sección 1232g, 34 C.F.R. Parte 99)).",
    "Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.":
      "Ejemplos: Registros educativos directamente relacionados con un estudiante mantenidos por una institución educativa o una parte que actúa en su nombre, como calificaciones, transcripciones, listas de clases, horarios de estudiantes, códigos de identificación de estudiantes, información financiera de estudiantes o registros disciplinarios de estudiantes.",
    "Category K: Inferences drawn from other personal information.":
      "Categoría K: Inferencias extraídas de otra información personal.",
    "Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.":
      "Ejemplos: Perfil que refleja las preferencias, características, tendencias psicológicas, predisposiciones, comportamiento, actitudes, inteligencia, habilidades y aptitudes de una persona.",
    "Category L: Sensitive personal information.":
      "Categoría L: Información personal sensible.",
    "Examples: Account login and password information, geolocation data.":
      "Ejemplos: Información de inicio de sesión y contraseña de la cuenta, datos de geolocalización.",
    "Under CCPA/CPRA, personal information does not include:":
      "Bajo el CCPA/CPRA, la información personal no incluye:",
    "Publicly available information from government records":
      "Información disponible públicamente de los registros gubernamentales",
    "Deidentified or aggregated consumer information":
      "Información de consumidores desidentificada o agregada",
    "Information excluded from the CCPA/CPRA's scope, such as:":
      "Información excluida del ámbito de aplicación del CCPA/CPRA, como:",
    "Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data":
      "Información de salud o médica cubierta por la Ley de Portabilidad y Responsabilidad de Seguros de Salud de 1996 (HIPAA) y la Ley de Confidencialidad de Información Médica de California (CMIA) o datos de ensayos clínicos",
    "Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994":
      "Información personal cubierta por ciertas leyes de privacidad específicas del sector, incluidas la Ley de Informes de Crédito Justo (FRCA), la Ley Gramm-Leach-Bliley (GLBA) o la Ley de Privacidad de Información Financiera de California (FIPA) y la Ley de Protección de la Privacidad del Conductor de 1994",
    "Sources of Personal Information": "Fuentes de Información Personal",
    "We obtain the categories of personal information listed above from the following categories of sources:":
      "Obtenemos las categorías de información personal enumeradas anteriormente de las siguientes fuentes:",
    "Directly from You": "Directamente de usted",
    ". For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service.":
      ". Por ejemplo, de los formularios que completa en nuestro Servicio, las preferencias que expresa o proporciona a través de nuestro Servicio, o de sus compras en nuestro Servicio.",
    "Indirectly from You": "Indirectamente de usted",
    ". For example, from observing Your activity on our Service.":
      ". Por ejemplo, al observar su actividad en nuestro Servicio.",
    "Automatically from You": "Automáticamente de usted",
    ". For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.":
      ". Por ejemplo, a través de cookies que nosotros o nuestros Proveedores de Servicios configuramos en su dispositivo mientras navega por nuestro Servicio.",
    "From Service Providers": "De los Proveedores de Servicios",
    ". For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors to deliver targeted advertising to You, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You.":
      ". Por ejemplo, proveedores externos para monitorear y analizar el uso de nuestro Servicio, proveedores externos para ofrecer publicidad dirigida a usted, proveedores externos para el procesamiento de pagos, u otros proveedores externos que utilizamos para brindar el Servicio a usted.",
    "Use of Personal Information": "Uso de la Información Personal",
    "We may use or disclose personal information We collect for 'business purposes' or 'commercial purposes' (as defined under the CCPA/CPRA), which may include the following examples:":
      "Podemos usar o divulgar la información personal que recopilamos para 'fines comerciales' o 'fines comerciales' (según lo definido en el CCPA/CPRA), que pueden incluir los siguientes ejemplos:",
    "To operate our Service and provide You with Our Service.":
      "Para operar nuestro Servicio y brindarle nuestro Servicio.",
    "To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.":
      "Para brindarle soporte y responder a sus consultas, incluidas la investigación y resolución de sus inquietudes y la supervisión y mejora de nuestro Servicio.",
    "To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery.":
      "Para cumplir o satisfacer el motivo por el cual proporcionó la información. Por ejemplo, si comparte su información de contacto para hacer una pregunta sobre nuestro Servicio, utilizaremos esa información personal para responder a su consulta. Si proporciona su información personal para comprar un producto o servicio, utilizaremos esa información para procesar su pago y facilitar la entrega.",
    "To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.":
      "Para responder a solicitudes de las fuerzas del orden y según lo requiera la ley aplicable, una orden judicial o regulaciones gubernamentales.",
    "As described to You when collecting Your personal information or as otherwise set forth in the CCPA/CPRA.":
      "Como se le describió al recopilar su información personal o como se establece en el CCPA/CPRA.",
    "For internal administrative and auditing purposes.":
      "Para fines administrativos y de auditoría internos.",
    "To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.":
      "Para detectar incidentes de seguridad y proteger contra actividades maliciosas, engañosas, fraudulentas o ilegales, incluidas, cuando sea necesario, para enjuiciar a los responsables de tales actividades.",
    "Other one-time uses.": "Otros usos únicos.",
    'We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us by contacting Us.':
      'No vendemos la información personal de los Consumidores que realmente sabemos que tienen menos de 16 años de edad, a menos que recibamos autorización afirmativa (el "derecho a participar") del Consumidor que tiene entre 13 y 16 años de edad, o el padre o tutor de un Consumidor menor de 13 años de edad. Los consumidores que opten por la venta de información personal pueden optar por no participar en ventas futuras en cualquier momento. Para ejercer el derecho de exclusión voluntaria, usted (o su representante autorizado) puede enviarnos una solicitud comunicándose con nosotros.',
    'As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer\'s personal information by the Business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.':
      'Como se define en la CCPA/CPRA, "vender" y "venta" significan vender, alquilar, liberar, divulgar, difundir, poner a disposición, transferir o comunicar de otro modo de forma oral, escrita o por medios electrónicos o de otro tipo, a un Consumidor. s información personal por parte de la Empresa a un tercero a cambio de una contraprestación valiosa. Esto significa que es posible que hayamos recibido algún tipo de beneficio a cambio de compartir información personal, pero no necesariamente un beneficio monetario.',
    'Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data" section.':
      'Tenga en cuenta que los ejemplos proporcionados anteriormente son ilustrativos y no pretenden ser exhaustivos. Para obtener más detalles sobre cómo utilizamos esta información, consulte la sección "Uso de sus datos personales".',
    'We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as defined under the CCPA/CPRA), which may include the following examples:':
      'Podemos usar o divulgar la información personal que recopilamos para "fines comerciales" o "fines comerciales" (como se define en CCPA/CPRA), que pueden incluir los siguientes ejemplos:',
    "Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the 'Use of Your Personal Data' section.":
      "Tenga en cuenta que los ejemplos proporcionados anteriormente son ilustrativos y no están destinados a ser exhaustivos. Para obtener más detalles sobre cómo utilizamos esta información, consulte la sección 'Uso de sus datos personales'.",
    "If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes, We will update this Privacy Policy.":
      "Si decidimos recopilar categorías adicionales de información personal o utilizar la información personal que recopilamos para fines materialmente diferentes, no relacionados o incompatibles, actualizaremos esta Política de Privacidad.",
    "Disclosure of Personal Information": "Divulgación de Información Personal",
    "We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:":
      "Podemos usar o divulgar y podemos haber usado o divulgado en los últimos doce (12) meses las siguientes categorías de información personal para fines comerciales o comerciales:",
    "Category A: Identifiers": "Categoría A: Identificadores",
    "Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))":
      "Categoría B: Categorías de información personal enumeradas en el estatuto de Registros de Clientes de California (Cal. Civ. Code § 1798.80(e))",
    "Category D: Commercial information": "Categoría D: Información comercial",
    "Category F: Internet or other similar network activity":
      "Categoría F: Actividad en Internet u otra actividad similar en la red",
    "Please note that the categories listed above are those defined in the CCPA/CPRA. This does not mean that all examples of that category of personal information were in fact disclosed, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been disclosed.":
      "Tenga en cuenta que las categorías enumeradas anteriormente son las definidas en el CCPA/CPRA. Esto no significa que todos los ejemplos de esa categoría de información personal hayan sido realmente divulgados, sino que refleja nuestra creencia de buena fe, en la medida de nuestro conocimiento, de que parte de esa información de la categoría aplicable puede haber sido y puede haber sido divulgada.",
    "When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.":
      "Cuando divulgamos información personal con un propósito comercial o un propósito comercial, celebramos un contrato que describe el propósito y requiere que el destinatario mantenga esa información personal confidencial y no la use para ningún propósito excepto para cumplir con el contrato.",
    "Share of Personal Information": "Compartir Información Personal",
    "We may share, and have shared in the last twelve (12) months, Your personal information identified in the above categories with the following categories of third parties:":
      "Podemos compartir, y hemos compartido en los últimos doce (12) meses, su información personal identificada en las categorías anteriores con las siguientes categorías de terceros:",
    "Service Providers": "Proveedores de Servicios",
    "Payment processors": "Procesadores de pagos",
    "Our affiliates": "Nuestras afiliadas",
    "Our business partners": "Nuestros socios comerciales",
    "Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You":
      "Proveedores externos a quienes usted o sus agentes nos autorizan a divulgar su información personal en relación con los productos o servicios que le brindamos",
    "Sale of Personal Information": "Venta de Información Personal",
    "As defined in the CCPA/CPRA, 'sell' and 'sale' mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information by the Business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.":
      "Según lo definido en el CCPA/CPRA, 'vender' y 'venta' significan vender, alquilar, divulgar, difundir, poner a disposición, transferir o comunicar de otra manera, oralmente, por escrito o por medios electrónicos u otros medios, la información personal de un Consumidor por parte de la Empresa a un tercero a cambio de una contraprestación valiosa. Esto significa que podemos haber recibido algún tipo de beneficio a cambio de compartir información personal, pero no necesariamente un beneficio monetario.",
    "We do not sell personal information as the term sell is commonly understood. We do allow Service Providers to use Your personal information for the business purposes described in Our Privacy Policy, for activities such as advertising, marketing, and analytics, and these may be deemed a sale under CCPA/CPRA.":
      "No vendemos información personal como se entiende comúnmente el término vender. Permitimos que los Proveedores de Servicios utilicen su información personal para los fines comerciales descritos en nuestra Política de Privacidad, para actividades como publicidad, marketing y análisis, y estas actividades pueden considerarse una venta según el CCPA/CPRA.",
    "We may sell and may have sold in the last twelve (12) months the following categories of personal information:":
      "Podemos vender y podemos haber vendido en los últimos doce (12) meses las siguientes categorías de información personal:",
    "Please note that the categories listed above are those defined in the CCPA/CPRA. This does not mean that all examples of that category of personal information were in fact sold, but reflects our good faith belief to the best of Our knowledge that some of that information from the applicable category may be and may have been shared for value in return.":
      "Tenga en cuenta que las categorías enumeradas anteriormente son las definidas en el CCPA/CPRA. Esto no significa que todos los ejemplos de esa categoría de información personal hayan sido realmente vendidos, sino que refleja nuestra creencia de buena fe, en la medida de nuestro conocimiento, de que parte de esa información de la categoría aplicable puede haber sido y puede haber sido compartida a cambio de un valor.",
    "Sale of Personal Information of Minors Under 16 Years of Age":
      "Venta de Información Personal de Menores de 16 Años",
    "Evaluation results": "Resultados de la evaluación",
    "to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered onboarding.":
      "para gestionar su registro como usuario del Servicio. Los Datos personales que proporciona pueden brindarle acceso a diferentes funcionalidades del Servicio que están disponibles para Usted como incorporación registrada.",
    "We do not knowingly collect personal information from minors under the age of 16 through our Service, although certain third party websites that we link to may do so. These third-party websites have their own terms of use and privacy policies and We encourage parents and legal guardians to monitor their children's Internet usage and instruct their children to never provide information on other websites without their permission.":
      "No recopilamos deliberadamente información personal de menores de 16 años a través de nuestro Servicio, aunque ciertos sitios web de terceros a los que enlazamos pueden hacerlo. Estos sitios web de terceros tienen sus propios términos de uso y políticas de privacidad y recomendamos a los padres y tutores legales que supervisen el uso de Internet de sus hijos y les instruyan para que nunca proporcionen información en otros sitios web sin su permiso.",
    "We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the 'right to opt-in') from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us by contacting Us.":
      "No vendemos la información personal de los consumidores que sabemos que tienen menos de 16 años, a menos que recibamos una autorización afirmativa (el 'derecho a optar por participar') del consumidor que tiene entre 13 y 16 años, o del padre o tutor de un consumidor menor de 13 años. Los consumidores que opten por la venta de información personal pueden optar por no participar en futuras ventas en cualquier momento. Para ejercer el derecho a optar por no participar, usted (o su representante autorizado) puede enviarnos una solicitud poniéndose en contacto con nosotros.",
    "If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information.":
      "Si tiene motivos para creer que un niño menor de 13 años (o 16) nos ha proporcionado información personal, contáctenos con detalles suficientes para que podamos eliminar esa información.",
    "Your Rights under the CCPA/CPRA": "Sus Derechos bajo el CCPA/CPRA",
    "The CCPA/CPRA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:":
      "El CCPA/CPRA otorga a los residentes de California derechos específicos con respecto a su información personal. Si usted es residente de California, tiene los siguientes derechos:",
    "The right to notice.": "El derecho a ser informado.",
    "You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.":
      "Tiene derecho a ser notificado sobre las categorías de Datos Personales que se están recopilando y los fines para los cuales se utilizan los Datos Personales.",
    "The right to know/access.": "El derecho a saber/acceder.",
    "Under CCPA/CPRA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:":
      "Bajo el CCPA/CPRA, tiene derecho a solicitar que le revelemos información sobre nuestra recopilación, uso, venta, divulgación para fines comerciales y compartición de información personal. Una vez que recibamos y confirmemos su solicitud, le revelaremos:",
    "The categories of personal information We collected about You":
      "Las categorías de información personal que recopilamos sobre usted",
    "The categories of sources for the personal information We collected about You":
      "Las categorías de fuentes de la información personal que recopilamos sobre usted",
    "Our business or commercial purposes for collecting or selling that personal information":
      "Nuestros propósitos comerciales o comerciales para recopilar o vender esa información personal",
    "The categories of third parties with whom We share that personal information":
      "Las categorías de terceros con los que compartimos esa información personal",
    "The specific pieces of personal information We collected about You":
      "Las piezas específicas de información personal que recopilamos sobre usted",
    "If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:":
      "Si vendimos su información personal o divulgamos su información personal para un propósito comercial, le revelaremos:",
    "The categories of personal information categories sold":
      "Las categorías de categorías de información personal vendida",
    "The categories of personal information categories disclosed":
      "Las categorías de categorías de información personal divulgadas",
    "The right to say no to the sale or sharing of Personal Data (opt-out).":
      "El derecho a decir no a la venta o compartición de Datos Personales (opt-out).",
    "You have the right to direct Us to not sell Your personal information. To submit an opt-out request, please see the 'Do Not Sell My Personal Information' section or contact Us.":
      "Tiene derecho a indicarnos que no vendamos su información personal. Para enviar una solicitud de exclusión, consulte la sección 'No venda mi información personal' o comuníquese con nosotros.",
    "The right to correct Personal Data.":
      "El derecho a corregir Datos Personales.",
    "You have the right to correct or rectify any inaccurate personal information about You that We collected. Once We receive and confirm Your request, We will use commercially reasonable efforts to correct (and direct our Service Providers to correct) Your personal information, unless an exception applies.":
      "Tiene derecho a corregir o rectificar cualquier información personal inexacta sobre usted que hayamos recopilado. Una vez que recibamos y confirmemos su solicitud, utilizaremos esfuerzos comercialmente razonables para corregir (y dirigir a nuestros Proveedores de Servicios para que corrijan) su información personal, a menos que se aplique una excepción.",
    "The right to limit use and disclosure of sensitive Personal Data.":
      "El derecho a limitar el uso y divulgación de Datos Personales sensibles.",
    "You have the right to request to limit the use or disclosure of certain sensitive personal information We collected about You, unless an exception applies. To submit, please see the 'Limit the Use or Disclosure of My Sensitive Personal Information' section or contact Us.":
      "Tiene derecho a solicitar limitar el uso o la divulgación de cierta información personal sensible que hemos recopilado sobre usted, a menos que se aplique una excepción. Para enviar una solicitud, consulte la sección 'Limitar el uso o la divulgación de mi información personal sensible' o comuníquese con nosotros.",
    "The right to delete Personal Data.":
      "El derecho a eliminar Datos Personales.",
    "You have the right to request the deletion of Your Personal Data under certain circumstances, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:":
      "Tiene derecho a solicitar la eliminación de sus Datos Personales en determinadas circunstancias, sujeto a ciertas excepciones. Una vez que recibamos y confirmemos su solicitud, eliminaremos (y dirigiremos a nuestros Proveedores de Servicios para que eliminen) su información personal de nuestros registros, a menos que se aplique una excepción. Podemos denegar su solicitud de eliminación si es necesario conservar la información para nosotros o para nuestros Proveedores de Servicios para:",
    "Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.":
      "Completar la transacción para la cual recopilamos la información personal, proporcionar un bien o servicio que solicitó, tomar medidas razonablemente anticipadas dentro del contexto de nuestra relación comercial continua con usted, o de otra manera cumplir con nuestro contrato con usted.",
    "Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.":
      "Detectar incidentes de seguridad, proteger contra actividades maliciosas, engañosas, fraudulentas o ilegales, o procesar a los responsables de dichas actividades.",
    "Debug products to identify and repair errors that impair existing intended functionality.":
      "Depurar productos para identificar y reparar errores que afectan la funcionalidad existente prevista.",
    "Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.":
      "Ejercer la libertad de expresión, garantizar el derecho de otro consumidor a ejercer sus derechos de libertad de expresión, o ejercer otro derecho previsto por la ley.",
    "Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).":
      "Cumplir con la Ley de Privacidad de las Comunicaciones Electrónicas de California (Código Penal de California § 1546 et. seq.).",
    "Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.":
      "Participar en investigaciones científicas, históricas o estadísticas de interés público o revisadas por pares que cumplan con todas las leyes de ética y privacidad aplicables, cuando la eliminación de la información probablemente haga imposible o perjudique seriamente la consecución de la investigación, si usted proporcionó previamente su consentimiento informado.",
    "Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.":
      "Habilitar únicamente usos internos que estén razonablemente alineados con las expectativas del consumidor basadas en su relación con nosotros.",
    "Comply with a legal obligation.": "Cumplir con una obligación legal.",
    "Make other internal and lawful uses of that information that are compatible with the context in which You provided it.":
      "Realizar otros usos internos y legales de esa información que sean compatibles con el contexto en el que la proporcionó.",
    "The right not to be discriminated against.":
      "El derecho a no ser discriminado.",
    "You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:":
      "Tiene derecho a no ser discriminado por ejercer cualquiera de los derechos de su consumidor, incluyendo:",
    "Denying goods or services to You": "Negarle bienes o servicios",
    "Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties":
      "Cobrar diferentes precios o tarifas por bienes o servicios, incluido el uso de descuentos u otros beneficios o la imposición de sanciones",
    "Providing a different level or quality of goods or services to You":
      "Proporcionarle un nivel o calidad diferente de bienes o servicios",
    "Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services":
      "Sugerir que recibirá un precio o tarifa diferente por bienes o servicios o un nivel o calidad diferente de bienes o servicios",
    "Exercising Your CCPA/CPRA Data Protection Rights":
      "Ejercer sus Derechos de Protección de Datos bajo el CCPA/CPRA",
    'Please see the "Do Not Sell My Personal Information" section and "Limit the Use or Disclosure of My Sensitive Personal Information" section for more information on how to opt out and limit the use of sensitive information collected.':
      "Consulte la sección 'No venda mi información personal' y la sección 'Limitar el uso o divulgación de mi información personal sensible' para obtener más información sobre cómo excluirse y limitar el uso de la información sensible recopilada.",
    "Additionally, in order to exercise any of Your rights under the CCPA/CPRA, and if You are a California resident, You can contact Us:":
      "Además, para ejercer cualquiera de sus derechos bajo el CCPA/CPRA, y si usted es residente de California, puede contactarnos:",
    "By email: service@trainify.app":
      "Por correo electrónico: service@trainify.app",
    "Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information.":
      "Solo usted, o una persona registrada con el Secretario de Estado de California que usted autorice para actuar en su nombre, puede hacer una solicitud verificable relacionada con su información personal.",
    "Your request to Us must:": "Su solicitud a nosotros debe:",
    "Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative":
      "Proporcionar suficiente información que nos permita verificar razonablemente que usted es la persona sobre la cual recopilamos información personal o un representante autorizado",
    "Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it":
      "Describa su solicitud con suficiente detalle que nos permita comprender, evaluar y responder adecuadamente",
    "We cannot respond to Your request or provide You with the required information if We cannot:":
      "No podemos responder a su solicitud o proporcionarle la información requerida si no podemos:",
    "Verify Your identity or authority to make the request":
      "Verificar su identidad o autoridad para hacer la solicitud",
    "And confirm that the personal information relates to You":
      "Y confirmar que la información personal está relacionada con usted",
    "We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.":
      "Divulgaremos y entregaremos la información requerida de forma gratuita dentro de los 45 días posteriores a la recepción de su solicitud verificable. El período de tiempo para proporcionar la información requerida puede extenderse una vez por otros 45 días cuando sea razonablemente necesario y con previo aviso.",
    "Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.":
      "Cualquier divulgación que proporcionemos solo cubrirá el período de 12 meses anterior a la recepción de la solicitud verificable.",
    "For data portability requests, We will select a format to provide Your personal information that is readily usable and should allow You to transmit the information from one entity to another entity without hindrance.":
      "Para las solicitudes de portabilidad de datos, seleccionaremos un formato para proporcionar su información personal que sea fácilmente utilizable y debería permitirle transmitir la información de una entidad a otra sin impedimentos.",
    "Do Not Sell My Personal Information": "No Venda Mi Información Personal",
    "You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.":
      "Tiene derecho a optar por no vender su información personal. Una vez que recibamos y confirmemos una solicitud verificable de un consumidor de su parte, dejaremos de vender su información personal. Para ejercer su derecho a optar por no participar, contáctenos.",
    "The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA/CPRA law. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales as defined under CCPA/CPRA law, you may do so by following the instructions below.":
      "Los Proveedores de Servicios con los que nos asociamos (por ejemplo, nuestros socios de análisis o publicidad) pueden usar tecnología en el Servicio que vende información personal según lo define la ley CCPA/CPRA. Si desea optar por no utilizar su información personal para fines publicitarios basados en intereses y estas posibles ventas según lo define la ley CCPA/CPRA, puede hacerlo siguiendo las instrucciones a continuación.",
    "Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that You use.":
      "Tenga en cuenta que cualquier exclusión es específica para el navegador que utiliza. Es posible que deba optar por no participar en cada navegador que utilice.",
    Website: "Sitio Web",
    'If applicable, click "Privacy Preferences", "Update Privacy Preferences" or "Do Not Sell My Personal Information" buttons listed on the Service to review Your privacy preferences and opt out of cookies and other technologies that We may use. Please note that You will need to opt out from each browser that You use to access the Service.':
      "Si corresponde, haga clic en los botones 'Preferencias de privacidad', 'Actualizar preferencias de privacidad' o 'No vender mi información personal' que se enumeran en el Servicio para revisar sus preferencias de privacidad y optar por no usar cookies y otras tecnologías que podamos usar. Tenga en cuenta que deberá optar por no participar en cada navegador que utilice para acceder al Servicio.",
    "Additionally, You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:":
      "Además, puede optar por no recibir anuncios personalizados según lo servido por nuestros Proveedores de Servicios siguiendo nuestras instrucciones presentadas en el Servicio:",
    "The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by Your browser, You will need to opt out again.":
      "La exclusión colocará una cookie en su computadora que es única para el navegador que usa para excluirse. Si cambia de navegador o elimina las cookies guardadas por su navegador, deberá excluirse nuevamente.",
    "Mobile Devices": "Dispositivos Móviles",
    "Your mobile device may give You the ability to opt out of the use of information about the apps You use in order to serve You ads that are targeted to Your interests:":
      "Su dispositivo móvil puede darle la capacidad de optar por no usar la información sobre las aplicaciones que usa para mostrarle anuncios que se orienten a sus intereses:",
    "'Opt out of Interest-Based Ads' or 'Opt out of Ads Personalization' on Android devices":
      "'Optar por no recibir anuncios basados en intereses' o 'Optar por no recibir anuncios personalizados' en dispositivos Android",
    "'Limit Ad Tracking' on iOS devices":
      "'Limitar el seguimiento de anuncios' en dispositivos iOS",
    "You can also stop the collection of location information from Your mobile device by changing the preferences on Your mobile device.":
      "También puede dejar de recopilar información de ubicación desde su dispositivo móvil cambiando las preferencias en su dispositivo móvil.",
    "Limit the Use or Disclosure of My Sensitive Personal Information":
      "Limitar el Uso o Divulgación de Mi Información Personal Sensible",
    "If You are a California resident, You have the right to limit the use and disclosure of Your sensitive personal information to that use which is necessary to perform the services or provide the goods reasonably expected by an average consumer who requests such services or goods.":
      "Si usted es residente de California, tiene derecho a limitar el uso y divulgación de su información personal sensible a ese uso que sea necesario para realizar los servicios o proporcionar los bienes razonablemente esperados por un consumidor promedio que solicita dichos servicios o bienes.",
    "We collect, use and disclose sensitive personal information in ways that are necessary to provide the Service. For more information on how We use Your personal information, please see the 'Use of Your Personal Data' section or contact us.":
      "Recopilamos, usamos y divulgamos información personal sensible de maneras que son necesarias para proporcionar el Servicio. Para obtener más información sobre cómo usamos su información personal, consulte la sección 'Uso de sus datos personales' o contáctenos.",
    "'Do Not Track' Policy as Required by California Online Privacy Protection Act (CalOPPA)":
      "Política de 'No Rastrear' según lo requerido por la Ley de Protección de la Privacidad en Línea de California (CalOPPA)",
    "Our Service does not respond to Do Not Track signals.":
      "Nuestro Servicio no responde a las señales de No Rastrear.",
    "However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.":
      "Sin embargo, algunos sitios web de terceros sí rastrean sus actividades de navegación. Si visita dichos sitios web, puede configurar sus preferencias en su navegador web para informar a los sitios web que no desea ser rastreado. Puede habilitar o deshabilitar DNT visitando la página de preferencias o configuración de su navegador web.",
    "Your California Privacy Rights (California's Shine the Light law)":
      "Sus Derechos de Privacidad en California (Ley Shine the Light de California)",
    "Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.":
      "Según la Sección 1798 del Código Civil de California (Ley Shine the Light de California), los residentes de California con una relación comercial establecida con nosotros pueden solicitar información una vez al año sobre el intercambio de sus Datos Personales con terceros para fines de marketing directo de los terceros.",
    "If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.":
      "Si desea solicitar más información bajo la ley Shine the Light de California, y si usted es residente de California, puede contactarnos utilizando la información de contacto proporcionada a continuación.",
    "California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)":
      "Derechos de Privacidad de California para Usuarios Menores de Edad (Sección 22581 del Código de Negocios y Profesiones de California)",
    "California Business and Professions Code Section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.":
      "La Sección 22581 del Código de Negocios y Profesiones de California permite a los residentes de California menores de 18 años que son usuarios registrados de sitios, servicios o aplicaciones en línea solicitar y obtener la eliminación de contenido o información que han publicado públicamente.",
    "To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.":
      "Para solicitar la eliminación de dichos datos, y si usted es residente de California, puede contactarnos utilizando la información de contacto proporcionada a continuación, e incluir la dirección de correo electrónico asociada con su cuenta.",
    "Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.":
      "Tenga en cuenta que su solicitud no garantiza la eliminación completa o exhaustiva del contenido o información publicada en línea y que la ley puede no permitir o requerir la eliminación en ciertas circunstancias.",
    "Children's Privacy": "Privacidad de los Niños",
    "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.":
      "Nuestro Servicio no está dirigido a nadie menor de 13 años. No recopilamos conscientemente información de identificación personal de nadie menor de 13 años. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado Datos Personales, contáctenos. Si nos damos cuenta de que hemos recopilado Datos Personales de alguien menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.",
    "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.":
      "Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de un padre, podemos requerir el consentimiento de su padre antes de recopilar y usar esa información.",
    "Links to Other Websites": "Enlaces a Otros Sitios Web",
    "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.":
      "Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de terceros, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.",
    "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.":
      "No tenemos control sobre el contenido, las políticas de privacidad o las prácticas de ningún sitio o servicio de terceros y no asumimos ninguna responsabilidad al respecto.",
    "Changes to this Privacy Policy": "Cambios en esta Política de Privacidad",
    "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.":
      "Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página.",
    'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.':
      "Le informaremos por correo electrónico y/o mediante un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigor y actualizaremos la fecha de 'Última actualización' en la parte superior de esta Política de Privacidad.",
    "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.":
      "Se le aconseja que revise esta Política de Privacidad periódicamente para ver si hay cambios. Los cambios en esta Política de Privacidad son efectivos cuando se publican en esta página.",
    "Contact Us": "Contáctenos",
    "If you have any questions about this Privacy Policy, You can contact us:":
      "Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:",
    "By visiting this page on our website:":
      "Visitando esta página en nuestro sitio web:",
    "Visit our Web Site": "Visite nuestro sitio web",
    " Thin wrists, physique with a tendency to be underweight":
      "Muñecas delgadas, Físico con tendecia a estar bajo de peso",
    "Wide wrists, slim build": "Muñecas anchas, complexion delgada",
    "Thin wrists, tendency to gain weight":
      "Muñecas pequeñas, físico con tendencia a subir de peso",
    "I don't consume": "No consumo",
    "Once a month": "Una vez al mes",
    "Twice a month": "2 veces al mes",
    "Once a week": "1 vez por semana",
    "More than one time per week": "Mas de una vez por semana",
    "Record yourself doing a high jump as high as you can. then watch the video where he jumps and press next":
      "Grabate a ti mismo haciendo un salto de altura lo más alto que puedas. Después, ve el video donde saltas y presiona siguiente",
    next: "siguiente",
    "When you went down to gain momentum to where you flexed your knees?":
      "Cuando bajaste para tomar impulso ¿hasta dónde flexionaste tus rodillas ?",
    "They barely flexed": "Apenas se flexionaron",
    "They were flexed at 90 degrees": "Se flexionaron a 90 grados",
    "They flexed quite a bit, more than 90 degrees.":
      "Se flexionaron bastante, mas de 90 grados",
    "Thin wrists, physique with a tendency to be underweight":
      "Muñecas delgadas, físico con tendencia a estar bajo de peso",
    "swipe up": "desliza hacia arriba",
    "I have been training constantly for 2 years at least 4 times a week":
      "llevo 2 años entrenando constantemente por lo menos 4 veces por semana",
    "I'm starting or resuming after a long time or I've been training for less than 2 years":
      "Voy empezando o retomando despues de un largo tiempo o llevo menos de 2 años entrenando",
    "I dedicate myself to sports professionally. I train 6 times a week. I have been training for more than 5 years":
      "Me dedico al deporte de forma profesional. Entreno 6 veces por semana. LLevo mas de 5 años entrenando",
    "your name is": "tu nombre es",
    "PROFILE SETTINGS": "AJUSTES DE PERFIL",
    Name: "Nombre",
    "enter your current password":
      "pon tu contraseña actual para hacer el cambio",
    "new password": "nueva contraseña",
    "Complete evaluation": "Completar evaluación",
    "As a first pass, complete your Strength assessment. You can tap where to complete the evaluation or tap the training icon to complete any of the evaluations we have.":
      "Como primer paso, completa tu evaluación de fuerza. Puedes dar un toque en completar evaluación o bien tocar el ícono de entrenamiento para realizar cualquiera de las evaluaciones que tenemos",
    "Welcome to Trainify!": "¡Bienvenido a Trainify!",
    "Write your allergies separated by commas":
      "Escribe tus alergias separadas por comas",
    breakfast: "desayuno",
    'refers to the California Consumer Privacy Act (the "CCPA") as amended by the California Privacy Rights Act of 2020 (the "CPRA").':
      'se refiere a la Ley de Privacidad del Consumidor de California (la "CCPA") modificada por la Ley de Derechos de Privacidad de California de 2020 (la "CPRA").',
    lunch: "lunch",
    '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Trainify.':
      '(denominada "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a Trainify.',
    ", for the purpose of CCPA/CPRA, refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.":
      ", a los efectos de CCPA/CPRA, se refiere a la Compañía como la entidad legal que recopila información personal de los Consumidores y determina los propósitos y medios del procesamiento de la información personal de los Consumidores, o en nombre de la cual se recopila dicha información y solo eso, o conjuntamente con otros, determina los propósitos y medios del procesamiento de la información personal de los consumidores que realizan negocios en el Estado de California.",
    ", for the purpose of the CCPA/CPRA, means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.":
      ", a los efectos de la CCPA/CPRA, significa una persona física que es residente de California. Un residente, tal como se define en la ley, incluye (1) cada individuo que se encuentra en los EE. UU. para un propósito que no sea temporal o transitorio, y (2) cada individuo que está domiciliado en los EE. UU. y que está fuera de los EE. UU. para un propósito temporal o transitorio. finalidad transitoria.",
    'means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.':
      'significa una entidad que controla, está controlada por o está bajo control común con una parte, donde "control" significa propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a votar para la elección de directores u otra autoridad administrativa.',
    "Physical condition (beginner, intermediate, advanced)":
      "Condición física (principiante, intermedio, avanzado)",
    ", for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.":
      ", a los efectos del RGPD (Reglamento General de Protección de Datos), se refiere a la Empresa como la persona jurídica que sola o junto con otras determina los fines y medios del procesamiento de Datos Personales.",

    meal: "comida",
    "Use of Sensitive and Health Data": "Uso de Datos Sensibles y de Salud",
    "User gender": "Género del usuario",
    "(DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.":
      "(DNT) es un concepto que ha sido promovido por las autoridades reguladoras de EE. UU., en particular la Comisión Federal de Comercio de EE. UU. (FTC), para que la industria de Internet desarrolle e implemente un mecanismo que permita a los usuarios de Internet controlar el seguimiento de sus actividades en línea en todos los sitios web.",
    Birthdate: "Fecha de nacimiento",
    "In addition to basic information such as name and email, Our app collects data related to health and condition user physical, including:":
      "Además de la información básica como el nombre y correo electrónico, nuestra aplicación recopila datos relacionados con la salud y la condición física del usuario, que incluyen:",
    "Type of muscle fibers": "Tipo de fibras musculares",
    collation: "colación",
    "The collection of data related to health and physical condition allows us to personalize training plans according to the user's specific needs. This data is used to:":
      "La recopilación de datos relacionados con la salud y condición física nos permite personalizar los planes de entrenamiento de acuerdo a las necesidades específicas del usuario. Estos datos son utilizados para:",
    "Flexibility level": "Nivel de flexibilidad",
    "Health questionnaire with questions related to medical history, lifestyle, alcohol consumption, smoking, etc.":
      "Cuestionario de salud con preguntas relacionadas con el historial médico, estilo de vida, consumo de alcohol, tabaquismo, etc.",
    Weight: "Peso",
    "Use of Sensitive and Health Data:": "Uso de Datos Sensibles y de Salud:",
    "Strength index": "Índice de fuerza",
    "Somatotype (ectomorph, mesomorph, endomorph)":
      "Somatotipo (ectomorfo, mesomorfo, endomorfo)",
    dinner: "cena",
    "Training days per week": "Días de entrenamiento por semana",
    "Heart rate at rest and during physical tests":
      "Frecuencia cardiaca en reposo y durante pruebas físicas",
    Injuries: "Lesiones",
    "food after dinner": "comida después de la cena",
    slide: "desliza",
    "Security Measures": "Medidas de seguridad",
    "We take the protection of personal and health data very seriously. Our application uses the following security measures:":
      "Nos tomamos muy en serio la protección de los datos personales y de salud. Nuestra aplicación utiliza las siguientes medidas de seguridad:",
    "Calculate trainable heart rate and Vo2 max using the Karvonen method.":
      "Calcular la frecuencia cardiaca entrenable y el Vo2 max usando el método de Karvonen.",
    "Welcome to the Exercise Selection!":
      "¡Bienvenido a la Selección de Ejercicios!",
    "Password encryption: Passwords are encrypted before being stored on our server.":
      "Cifrado de contraseñas: Las contraseñas son cifradas antes de ser almacenadas en nuestro servidor.",
    "Secure Authentication: We use JSON Web Tokens (JWT) to secure user sessions.":
      "Autenticación segura: Utilizamos JSON Web Tokens (JWT) para asegurar las sesiones de los usuarios.",
    "We restrict access to the database: Only our server instance has access to the database, even if the credentials are known.":
      "Restringimos el acceso a la base de datos: Solo nuestra instancia de servidor tiene acceso a la base de datos, incluso si las credenciales son conocidas.",
    "Limited IP Access: Access to our EC2 instance is limited to a specific IP, preventing unauthorized access.":
      "Acceso limitado por IP: El acceso a nuestra instancia de EC2 está limitado a una IP específica, lo que previene accesos no autorizados.",
    "Input validation: Data sent through the frontend is validated to prevent attacks and vulnerabilities.":
      "Validación de inputs: Los datos enviados a través del frontend son validados para prevenir ataques y vulnerabilidades.",
    "Using OAuth2 for selfies: User images, such as selfies, are obtained through Google Sign-In to ensure authenticity and security.":
      "Uso de OAuth2 para selfies: Las imágenes de los usuarios, como las selfies, se obtienen a través de Google Sign-In para garantizar autenticidad y seguridad.",
    "Adapt exercise routines according to the user's somatotype and type of muscle fibers.":
      "Adaptar las rutinas de ejercicios según el somatotipo del usuario y su tipo de fibras musculares.",
    "Ensure that recommended routines do not include exercises that may aggravate reported injuries.":
      "Asegurar que las rutinas recomendadas no incluyan ejercicios que puedan agravar lesiones reportadas.",
    "Monitor physical progress and adjust training plans according to the user's evolution.":
      "Monitorear el progreso físico y ajustar los planes de entrenamiento de acuerdo con la evolución del usuario.",
    "In this section you will find all the muscle groups that we have selected for you this month, according to the configuration you have selected and your physiological and anthropometric characteristics that we know about you.":
      "En esta seccion se encontrarán todos los grupos músculares que hemos seleccionado para ti para este mes de acuerdo a la configuración que has seleccionado y a tus características fisiológicas y antropométricas que conocemos de ti.",
    "From each muscle group, you can choose the exercise that is in your training area and that is easy for you to perform according to your technique. Once you know which one you want, press select.":
      "De cada grupo múscular, podrás eligir el ejercicio que se encuentre en tu área de entrenamiento y que te sea fácil realizarlo de acurdo a tu técnica. Una vez sepas cual quieres presiona seleccionar.",
    "Once you finish selecting the exercises for each muscle group, you can advance to the next part of the evaluation.":
      "Una vez que termines de seleccionar los ejercicios para cada grupo múscular, podrás avanzar a la siguiete parte de la evaluación.",
    "Understood!": "¡Entendido!",
    "If you have any restrictions from performing an exercise (such as fractures, operations or serious injuries) you can press the X to prevent this muscle group from being tested or appearing in your routine.":
      "Si tienes alguna restricción para no realizar algún ejercicio (como fracturas, operaciones o lesiones graves) puedes presionar la X para evitar que este grupo muscular se ponga a prueba o aparezca en tu rutina.",
    weight: "peso",
    reps: "repeticiones",
    "measure unit..": "unidad de medida..",
    "Welcome to the Exercise Assestment!":
      "¡Bienvenido a la Evaluación de Ejercicios!",
    "Now, with a weight that is not light but that you can handle with the correct technique described in the exercises, try to do 6 to 18 repetitions. Then write down the repetitions you did along with the weight and unit of measurement you used.":
      "Ahora bien, con un peso que no sea liviano pero que puedas manejar con la técnica correcta descrita en los ejercicios, intenta hacer de 6 a 18 repeticiones. Luego anota las repeticiones que hiciste junto con el peso y la unidad de medida que utilizaste.",
    "Once you finish completing the test of each exercise you will be able to finish the evaluation.":
      "Una vez que termines de completar la evaluación de cada ejercicio podrás finalizar la evaluación.",
    "You have completed your strength assessment. Now you will be able to access your routine, you will also be able to see your dashboard panel where it will tell you the days you should train.":
      "Has completado tu evaluación de fuerza. Ahora podrás acceder a tu rutina, también podrás ver tu 'Tablero' donde te indicará los días que debes entrenar.",
    "Keep in mind": "Toma en cuenta",
    "Congratulations!": "¡Felicitaciones!",
    "If you enter your routine and it tells you that you should rest today, it is because the current day is not among the days you chose. If you want to change the days you can do it in settings. Don't forget to complete the other evaluations.":
      "Si ingresas a tu rutina y te dice que hoy debes descansar es porque el día actual no está entre los días que elegiste. Si quieres cambiar los días puedes hacerlo en configuración. No olvides completar las demás evaluaciones.",
    "Once your evaluation is complete you will be able to see the dashboards that will tell you what you should do today. You can also check the progress of each type of session by pressing the triangle":
      "Una vez completada tu evaluación podrás ver los tableros que te indicarán lo que debes hacer hoy. También puedes consultar el progreso de cada tipo de sesión presionando el triángulo",
    "Learn to use you Dashboard": "Aprende a usar tu Tablero",
    Help: "Ayuda",
    "How to use your Dashboard?": "¿Cómo usar tu Tablero?",
    "How to do the Exercise Assestment?":
      "¿Cómo hacer la Evaluación de Ejercicios?",
    "How to make Exercise Selection?":
      "¿Cómo hacer la Selección de Ejercicios?",
    "What to do after completing the strength assessment?":
      "¿Qué hacer después de finalizar la evaluación de fuerza?",
    "Help Section": "Sección de Ayuda",
    "In this section you can find all the instructions for the application, including this one. Simply press any of them and you will be able to see the instructions.":
      "En esta sección podrás encontrar todas las instrucciones para la aplicación incluida ésta. Simplemente presiona cualquiera de ellos y podrás ver las instrucciones.",
    "Log Out": "Finalizar la Sesión",
    "Finally, here you can end the session.":
      "Finalmente, aquí podrás finalizar la sesión.",
    "Welcome to your Strength Routine!": "¡Bienvenido a tu Rutina de Fuerza!",
    "Follow the exercises as detailed in the images. Here you will find the weight and the number of repetitions you should do for each. Remember that everything is calculated according to your monthly evaluation.":
      "Sigue los ejercicios tal y como se ejecutan en las imágenes. Aquí encontrarás el peso y el número de repeticiones que debes hacer por cada uno. Recuerda que todo se calcula según tu evaluación mensual.",
    "After finishing the reps, press rest so that the correct rest is counted.When the counter ends, a series of that block of exercises will be deducted.If you made a mistake, you can press restore and all series will be recalculated from the beginning.":
      "Al terminar las repeticiones, pulsa 'Descansar' para calcular el tiempo. Cuando termine el contador, se descontará 1 serie del bloque. Si cometiste un error, presiona 'Restaurar' para recalcular todas las series desde el inicio.",
    "After finishing the sets, the exercise block will go dark and is a sign that you have successfully finished that set block.":
      "Después de terminar las series, el bloque de ejercicios se oscurecerá y es una señal de que has terminado con éxito ese bloque de series.",
    "When you see monosets in the upper left of each exercise block, it means that you are going to perform only that exercise and when you finish the sets you can perform another exercise.":
      "Cuando veas monoseries en la parte superior izquierda de cada bloque de ejercicios, significa que vas a realizar solo ese ejercicio y cuando termines las series podrás realizar otro ejercicio.",
    "How do exercise blocks work?":
      "¿Cómo funcionan los bloques de ejercicios?",
    "When you see bisets in the upper left part of each exercise block, it means that you are going to perform only those 2 exercises and when you finish the sets you can perform another exercise block.":
      "Cuando veas biseries en la parte superior izquierda de cada bloque de ejercicios, significa que vas a realizar solo esos 2 ejercicios y cuando termines las series podrás realizar otro bloque de ejercicios.",
    "When you see trisets in the upper left of each exercise block, it means that you are going to perform only those 3 exercises and when you finish the sets you can perform another exercise block.":
      "Cuando veas triseries en la parte superior izquierda de cada bloque de ejercicios, significa que vas a realizar solo esos 3 ejercicios y cuando termines las series podrás realizar otro bloque de ejercicios.",
    "When you see a circuit in the upper left part of each exercise block, it means that you are going to perform those 4 exercises without resting and when you finish the series you can perform another exercise block.":
      "Cuando veas circuito en la parte súperior izquierda de cada bloque de ejercicios, significa que vas a realizar esos 4 ejercicios sin descansar y cuando termines las series podrás realizar otro bloque de ejercicios.",
    "How do rest and series work?": "¿Cómo funciona el descanso y las series?",
    "Once you have finished all the blocks you can finish your routine by pressing the 'Trophy'":
      "Una vez que hayas terminado todos los bloques podrás finalizar tu rutina presionando el 'Trofeo'",
    "Today is your flex off day": "Hoy te toca descansar de flexibilidad",
    "Congratulations! You have completed your daily strength routine.":
      "¡Felicidades! Has completado tu rutina diaria de fuerza.",
    "Complete the cardio evaluation first":
      "Completa primero la evaluación de cadio",
    "Complete the flex evaluation first":
      "Completa primero la evaluación de flex",
    "Start of Cardiovascular Evaluation": "Inicio de Evaluación Cardiovascular",
    "birthday:": "cumpleaños:",
    "resting heart rate": "frecuencia cardiaca en reposo",
    "Complete the requested fields starting with weight, resting heart rate, and your date of birth.":
      "Complete los campos solicitados comenzando con peso, frecuencia cardíaca en reposo y su fecha de nacimiento.",
    "Welcome to the Cardio Assestment!":
      "¡Bienvenido a la Evaluación Cardiovascular!",
    "You can measure your resting heart rate using your smart watch or bracelet, then place it in the field.":
      "Puedes medir tu frecuencia cardíaca en reposo usando tu reloj o pulsera inteligente y colocarlo en el campo.",
    "If you don't have a smart watch or bracelet, don't worry, place your index and ring fingers on the left side of your neck under your chin, count the heartbeats for 15 seconds, then multiply those heartbeats by 4 and put them in the field.":
      "Si no tienes reloj o pulsera inteligente, no te preocupes, coloca tus dedos índice y anular en el lado izquierdo de tu cuello debajo de tu barbilla, cuenta las pulsaciones durante 15 segundos, luego multiplica esas pulsaciones por 4 y ponlas en el campo.",
    "Select a test to take your Vo2Max exam":
      "Selecciona una prueba para realizar tu examen Vo2Max",
    "Queen's College Test": "Prueba de Queen's College",
    "We already have your gender so we will set the speed of the metronome":
      "Ya tenemos tu género así que nosotros marcaremos la velocidad del metrónomo",
    "Remember, you should walk as fast as possible 1.6 miles on the treadmill. When you finish press finish":
      "Recuerda, debes caminar lo más rápido posible 1,6 millas en la cinta. Cuando termines presiona finalizar",
    "Immediately take your heart rate again and enter the data in the field to end the test.":
      "Inmediatamente vuelvete a tomar la frecuencia cardíaca e ingresa los datos en el campo para finalizar la prueba.",
    "Select the exercise with which you plan to perform your cardio sessions":
      "Selecciona el ejercicio con el que piensas realizar tus sesiones de cardio",
    "Flexibility Assestment": "Evauación de flexibilidad",
    "Today I have to:": "Hoy tengo que hacer:",
    "Once you finish a strength session, a section will appear on your dashboard that will be the deadline to complete the weekly strength routine.":
      "Una vez que termines una sesión de fuerza, aparecerá una sección en tu 'Tablero' que será la fecha límite para completar la rutina de fuerza semanal.",
    Rest: "Descansar",
    "When you successfully complete a week of training, you will advance to the next level. Otherwise 1 point will be subtracted from your current level. There are 50 levels, what are you waiting for!":
      "Cuando completes con éxito una semana de entrenamiento, pasarás al siguiente nivel. De lo contrario se restará 1 punto de tu nivel actual. Hay 50 niveles, ¡qué estás esperando!",
    "Level up!": "Subir de nivel!",
    "Know your Deadline!": "¡Conoce tu Fecha Limite!",
    "These exercises will improve your muscle function and coordination by stimulating proprioceptive receptors. These exercises do not change, as they use common patterns and positions that mimic functional movements.":
      "Estos ejercicios mejorarán tu función muscular y coordinación al estimular los receptores propioceptivos. Estos ejercicios no cambian, ya que utilizan patrones y posiciones comunes.",
    "You are asked to hold the flex position for 15 seconds and then relax. Press play to have the stopwatch count down the time.":
      "Se te pide que mantengas la posición de flexión durante 15 segundos y luego  relajes. Presiona 'estira' para que el cronómetro cuente el tiempo.",
    "Once you complete your flexibility routine you will be able to see your progress reflected in the progress section of your dashboard.":
      "Una vez que completes tu rutina de flexibilidad podrás ver el progreso reflejado en la sección de progreso de tu 'Tablero'.",
    "Congratulations! you completed your daily cardio routine!":
      "¡Felicidades! ¡Has completado tu rutina cardiovascular diaria!",
    "Congratulations! you completed your daily flex routine!":
      "¡Felicidades! ¡Has completado tu rutina de flex diaria!",
    "Welcome to your flexibility routine!":
      "¡Bienvenido a tu rutina de Flexibilidad!",
    "How it works?": "¿Cómo funciona?",
    "Track your progress!": "¡Observa tu progreso!",
    "Press 'start' to begin your routine.":
      "Presiona 'Empieza' para comenzar tu rutina.",
    "Once you are ready on the cardio machine you selected, press 'start' to begin your routine.":
      "Estando listo en la maquina de cardio que seleccionaste, presiona 'iniciar' para comenzar tu rutina.",
    "In the upper right part it will indicate the minutes that you are going to perform the exercise. It will also indicate the pulses that you must have for the training to be successful.":
      "En la parte superior derecha indicará los minutos que vas a realiazar el ejercicio. Tambien indicará las pulsaciones que debes llevar para que el entrenamiento sea exitoso.",
    "You can measure your heart rate with your smart watch or bracelet or by taking it from the treadmill sensors.":
      "Puedes medir tu frecuencia cardíaca con tu reloj o pulsera inteligente o tomándola de los sensores de la caminadora.",
    "Once you finish your cardio routine, you will be able to see your progress on the dashboard.":
      "Una vez que termines tu rutina de cardio, podrás ver tu progreso en el 'tablero'.",
    "Welcome to your Cardio routine!": "¡Bienvenido a tu rutina de Cardio!",
    "How Strength Routine works?": "¿Cómo funciona la rutina de fuerza?",
    "How Flex Routine works?": "¿Cómo funciona la rutina de flexibilidad?",
    "How Cardio Routine works?": "¿Cómo funciona la rutina de cardio?",
    "change environment or days number":
      "cambiar lugar de entrenamiento o numero de días seleccionados",
    "If you want to change the number of training days or the training environment, this action would delete the current evaluation and you will need to create a new one. Type DELETE-EVALUATION to proceed":
      "Si deseas cambiar la cantidad de días de entrenamiento o el entorno, se eliminará la evaluación actual y deberás crear una nueva. Escribe BORRAR-EVALUACIÓN para continuar",
    "Days order": "Orden de los días",
    "Exercise tempo execution": "Tempo de ejecusión de los ejercicios",
    "Session duration": "Duración de la sesión",
    "your current session duration is": "la duración de tu sesión actual es",
    "your current tempo time is": "tu tempo actual es",
    STRENGTH: "FUERZA",
    "It looks like you don't have an Internet connection":
      "Parece que no tienes conexión a Internet",
    "Cardio exercise selected": "Ejercicio de cardio seleccionado",
    "Press the boxes of the days you want to order your week of cardio training":
      "presiona las casillas de los días que quieres para ordenar tu semana de entrenamiento cardio",
    "Training on the range makes a difference!":
      "Entrenar en el rango hace la diferencia!",
    "Complete all fields": "Completa todos los campos",
    "Invalid Email": "Correo inválido",
    "...sending strength evaluation": "...enviado la evaluación de fuerza",
    "...sending health form": "...enviado cuestionario de salud",
    "...sending cardio evaluation": "...enviado la evaluación de cardio",
    "...sending flex evaluation": "...enviado la evaluación de flexibilidad",
    "...updating your level": "...actualizando tu nivel",
    "...logging into your account": "...entrando a la cuenta",
    "When you have successfully completed the month's routine or when 1 and a half months have passed, the evaluation will expire and you will have to do another one in order to calculate your plan based on your new condition.":
      "Cuando hayas completado con éxito la rutina del mes o cuando haya pasado 1 mes y medio, la evaluación caducará y tendrás que hacer otra para poder calcular tu plan en función de tu nueva condición.",
    "Complete Strength Evaluation first":
      "Completa primero la evaluación de fuerza",
    "Please enter your email address so we can send you a link to reset your password":
      "Ingresa tu dirección de correo electrónico para que podamos enviarte un enlace para restablecer tu contraseña",
    "This password is too common.": "La contraseña es muy común",
    "This password is entirely numeric.":
      "La contraseña es totalmete numerica.",
    "user with this email address already exists.":
      "Un usuario con ese correo ya existe",
    "This password is too short. It must contain at least 8 characters.":
      "La contraseña es muy corta. Debe de tener al menos 8 caracteres.",
    "User account could not be created": "No se pudo crear tu cuenta",
    "...creating your account": "...creando tu cuenta",
    "User account created successfully":
      "Tú cuenta ha sido creada exitosamente",
    "...sending progress": "...enviando progreso",
    "For training at home you must be equipped with at least one of these accessories: suspension system straps or resistance band.":
      "Para entrenar en casa debes estar equipado con al menos uno de estos accesorios: sistema de correas de suspensión o banda de resistencia.",
    Equipment: "Equipo",
    "From each muscle group you can choose the exercise that you can do at home and that is easy for you to perform according to your technique. Once you know which one you want, press select.":
      "De cada grupo muscular podrás elegir el ejercicio que puedas hacer en tu casa y que te resulte fácil de realizar según tu técnica. Una vez que sepas cuál quieres, presiona seleccionar.",
    "This is the part where you sweat! How many repetitions can you do for each exercise? Perform the test doing the maximum number of repetitions without resting between them and write down the answer.":
      "¡Esta es la parte donde sudas! ¿Cuántas repeticiones puedes hacer por cada ejercicio? Realiza la prueba haciendo el máximo número de repeticiones sin descansar en entre ellas y anota la respuesta. ",
    "Once you complete all the tests you will be able to complete your evaluation":
      "Una vez que completes todas las pruebas podrás completar tu evalución",
    "...loading your plan": "...cargando tu plan",
    "Successfully created Health Form":
      "Se ha enviado tu formulario exitosamente",
    "For the next part you are going to use the gym equipment. Get ready!":
      "Para la siguiente parte vas a usar el equipo del gimnasio. ¡Preparate!",
    "From each muscle group you can choose the exercise that is in your gym and is easy for you to perform according to your technique. Once you know which one you want, press select.":
      "De cada grupo muscular podrás elegir el ejercicio que se encuentre en tu gimnasio y te resulte fácil de realizar según tu técnica. Una vez que sepas cuál quieres, presiona seleccionar.",
    "It's time to sweat! Choose a manageable but challenging weight. Do between 6 and 18 repetitions with the heaviest weight possible. Maintain good technique at all times. Write down the weight used (kg or lbs) and the repetitions you completed":
      "¡es la hora de sudar! Elige un peso manejable pero desafiante. Haz entre 6 y 18 repeticiones con el mayor peso posible. Mantén una buena técnica en todo momento. Anota el peso usado (kg o lbs) y las repeticiones completaste",
  },
};

export const dictionary = (sentence, language) => {
  if (language === "eng" && translateToSpanish.esp[sentence]) {
    return sentence;
  }
  // Para español, buscamos la traducción en el objeto
  if (language === "esp") {
    return translateToSpanish.esp[sentence] || sentence;
  }
  // Si el idioma o la frase no se encuentran, simplemente regresamos la frase original
  return sentence;
};
