import React from "react";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PrivacyPolicyScreen from "../components/others/PrivacyPolicyScreen";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Navbar darkBg />
      <PrivacyPolicyScreen />
    </Layout>
  );
}
