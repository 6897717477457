import React from "react";
import { dictionary } from "../../conf/dictionary";

export default function TermsScreen() {
  return (
    <section style={styles.container}>
      <h1 style={styles.header}>Terms and Conditions</h1>
      <p style={{ color: "white", fontSize: "20px", marginBottom: "30px" }}>
        {dictionary("Terms of Service - TRAINIFY", "esp")}
      </p>
      <h3 style={styles.subSubHeader}>{dictionary("1. General", "esp")}</h3>
      <h3 style={styles.subSubHeader}>
        {dictionary("1.1. Scope and purpose of the contract", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "These General Terms and Conditions (hereinafter 'GTC') set out the legal framework for the use of the Trainify app. Trainify is a fitness training app designed to generate personalized training plans, tailored to your preferences and needs. The app offers a variety of functionalities, including the recording and tracking of your training sessions.",
          "esp"
        )}
      </p>
      <p style={styles.paragraph}>
        {dictionary(
          "To use the application you must be over 18 years old.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>{dictionary("1.2. Supplier", "esp")}</h3>
      <p style={styles.paragraph}>
        {dictionary("The application provider is:", "esp")}
      </p>
      <p style={styles.paragraph}>
        {dictionary("TrainifyTechnologies", "esp")}
      </p>
      <h3 style={styles.subSubHeader}>{dictionary("2. Services", "esp")}</h3>
      <h3 style={styles.subSubHeader}>
        {dictionary("2.1. Free and paid services", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "You can use Trainify in its free basic version or subscribe to the paid Pro version, which unlocks all the features. For more details about the services included in both versions, check the app description in the App Store or Play Store.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("2.2. Changes in the scope of services", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "We reserve the right to make reasonable changes to the app's services, taking your interests into consideration.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("3. Contract Formation", "esp")}
      </h3>
      <h3 style={styles.subSubHeader}>
        {dictionary("3.1. App download", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "To begin using the app, you must download it from the App Store or Play Store.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("3.2. Basic version", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "To use the basic version, you need to register by providing your email address and choosing a password. By registering, you agree to these Terms and Conditions and our Privacy Policy.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("3.3. Pro version", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "You can subscribe to the Pro version with either a monthly or annual subscription, which is processed via the App Store or Play Store.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("4. User Obligations", "esp")}
      </h3>
      <h3 style={styles.subSubHeader}>
        {dictionary("4.1. Health requirements", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "Using the app is at your own risk. It is important to ensure you are in good health before starting any training program. If you have any pre-existing medical conditions, please consult a doctor before using the app.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("4.2. User Conduct", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "Users are required to maintain respectful behavior at all times. Aggressive, harassing, sexual, pornographic, violent, or any inappropriate conduct will not be tolerated. Trainify reserves the right to suspend or delete accounts that violate these rules.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("4.3. Usage Rights", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "You are granted a non-transferable right to use the app for personal purposes. Sharing your credentials with third parties is not allowed.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("5. Fees and Payment", "esp")}
      </h3>
      <h3 style={styles.subSubHeader}>{dictionary("5.1. Fees", "esp")}</h3>
      <p style={styles.paragraph}>
        {dictionary(
          "The subscription fees are detailed in the app description in the App Store or Play Store. Payment is due in advance for both monthly and yearly subscriptions.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("6. Termination of the Contract", "esp")}
      </h3>
      <h3 style={styles.subSubHeader}>
        {dictionary("6.1. Account Deletion", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "You can request the deletion of your account at any time by contacting us via email at support@trainify.app.",
          "esp"
        )}
      </p>
      <h3 style={styles.subSubHeader}>
        {dictionary("6.2. Termination for Cause", "esp")}
      </h3>
      <p style={{ ...styles.paragraph, marginBottom: "30px" }}>
        {dictionary(
          "Trainify reserves the right to terminate your account in case of significant violations of these Terms and Conditions.",
          "esp"
        )}
      </p>
    </section>
  );
}

const styles = {
  container: {
    padding: "40px",
    backgroundColor: "#222222",
    height: "auto",
    overflow: "hidden",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "20px",
    color: "#fff",
  },
  date: {
    fontSize: "14px",
    marginBottom: "10px",
    color: "#fff",
  },
  paragraph: {
    fontSize: "16px",
    marginBottom: "10px",
    color: "#fff",
  },
  subHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
    color: "#fff",
  },
  subSubHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "5px",
    color: "#fff",
  },
  h4: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "13px",
    marginBottom: "3px",
    color: "#fff",
  },
  list: {
    marginLeft: "10px",
    color: "#fff",
  },
  listItem: {
    fontSize: "16px",
    marginBottom: "5px",
    color: "#fff",
  },
  strong: {
    fontWeight: "bold",
    color: "#fff",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
};
